@import '../../../styles/mixins/index.scss';

.table-v2-design {
  padding: 0px;
  z-index: -1;

  @include breakpoint("sm-max") {
    padding: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px;
      text-align: left;
    }

    .search-filter {
      th {
        padding: 8px 12px;
        
        .icon-text-alignment {
          position: relative;
          
          input, select {
            width: 100%;
            padding: 6px 30px 6px 8px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            
            &::placeholder {
              font-size: 0.75rem;
              text-transform: uppercase;
              font-weight: 500;
              letter-spacing: 1px;
            }
          }

          .icon-align {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

// Status button styles
.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status styles
.Active {
  background-color: #008000 ;
  
  .dot {
    background-color: #008000;
  }
}

.Inactive {
  background-color: #FF0000 !important;
  
  .dot {
    background-color: #FF0000;
  }
}