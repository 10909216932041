.help-center-container {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8f9ff, #ffffff);
  
  .full-width {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 20px;
  }
}

.help-center-header {
  text-align: center;
  margin-bottom: 60px;
  
  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #2a3362;
  }
  
  p {
    color: #5a6387;
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.search-container {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  
  .search-input {
    flex: 1;
    padding: 16px 24px;
    border: 1px solid #e1e5ee;
    border-right: none;
    border-radius: 8px 0 0 8px;
    font-size: 16px;
    transition: all 0.3s;
    
    &:focus {
      outline: none;
      border-color: #4a6cf7;
      box-shadow: 0 0 0 2px rgba(74, 108, 247, 0.2);
    }
  }
  
  .search-button {
    padding: 16px 32px;
    background-color: #4a6cf7;
    color: white;
    border: none;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s;
    
    i {
      margin-right: 8px;
    }
    
    &:hover {
      background-color: #3a5ce5;
    }
  }
}

.help-center-content {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;
}

.categories-sidebar {
  width: 280px;
  flex-shrink: 0;
  
  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2a3362;
  }
  
  .category-list {
    list-style: none;
    padding: 0;
    margin: 0 0 40px 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    
    li {
      padding: 14px 20px;
      cursor: pointer;
      border-bottom: 1px solid #f0f2fa;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      
      .category-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        background-color: #e6f0ff;
        border-radius: 6px;
      }
      
      &:hover {
        background-color: #f8f9ff;
        color: #4a6cf7;
      }
      
      &.active {
        background-color: #4a6cf7;
        font-weight: 500;
        color: white;
        
        .category-icon {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      
      &:last-child {
        border-bottom: none;
      }
    }
  }
  
  .contact-support {
    padding: 30px 25px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    
    h3 {
      margin-bottom: 10px;
    }
    
    p {
      margin-bottom: 20px;
      color: #5a6387;
      font-size: 14px;
    }
    
    .contact-button {
      display: block;
      width: 100%;
      padding: 14px;
      background-color: #4a6cf7;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 600;
      transition: all 0.3s;
      
      &:hover {
        background-color: #3a5ce5;
        transform: translateY(-2px);
        box-shadow: 0 5px 10px rgba(74, 108, 247, 0.2);
      }
    }
  }
}

.faq-section {
  flex: 1;
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  
  h2 {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f2fa;
    color: #2a3362;
    font-size: 24px;
  }
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  border: 1px solid #e1e5ee;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
  }
  
  .faq-question {
    padding: 18px 20px;
    background-color: #f8f9ff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #2a3362;
    }
    
    .toggle-icon {
      font-size: 20px;
      color: #4a6cf7;
      font-weight: bold;
    }
  }
  
  .faq-answer {
    padding: 20px;
    border-top: 1px solid #f0f2fa;
    
    p {
      margin: 0 0 20px 0;
      color: #5a6387;
      line-height: 1.6;
    }
    
    .helpful-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;
      border-top: 1px solid #f0f2fa;
      
      p {
        margin: 0;
        font-size: 14px;
        color: #8890a6;
      }
      
      .helpful-buttons {
        display: flex;
        gap: 10px;
        
        button {
          padding: 6px 15px;
          background-color: #f0f2fa;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          color: #5a6387;
          
          &:hover {
            background-color: #e1e5ee;
          }
        }
      }
    }
  }
}

.no-results {
  padding: 60px 30px;
  text-align: center;
  background-color: #f8f9ff;
  border-radius: 8px;
  
  .no-results-icon {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  p {
    color: #5a6387;
    margin-bottom: 20px;
  }
  
  .reset-search {
    padding: 10px 20px;
    background-color: #4a6cf7;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    
    &:hover {
      background-color: #3a5ce5;
    }
  }
}

.popular-topics {
  margin-top: 40px;
  
  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #2a3362;
    font-size: 24px;
  }
  
  .topics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 25px;
  }
  
  .topic-card {
    background: white;
    border-radius: 12px;
    padding: 25px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.08);
    }
    
    .topic-icon {
      font-size: 32px;
      margin-bottom: 15px;
    }
    
    h3 {
      margin-bottom: 10px;
      color: #2a3362;
    }
    
    p {
      color: #5a6387;
      font-size: 14px;
    }
  }
}

@media (max-width: 992px) {
  .help-center-content {
    flex-direction: column;
  }
  
  .categories-sidebar {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .topics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .topics-grid {
    grid-template-columns: 1fr;
  }
  
  .search-container {
    flex-direction: column;
    
    .search-input {
      border-radius: 8px 8px 0 0;
      border-right: 1px solid #e1e5ee;
      border-bottom: none;
    }
    
    .search-button {
      border-radius: 0 0 8px 8px;
    }
  }
}

