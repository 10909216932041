.login-page-ui-slider {
    max-width: 568px;
    margin: 0 auto;

    .slick-dots {
        bottom: -50px;
    }
    
    .slick-dots li button {
        padding: 0;
    }

    .slick-dots li button:before {
        font-size: 10px;
    }
    .slick-dots li.slick-active button:before {
            color: #1477e7;
            opacity: 1;
    }
    .slick-dots li {
        margin: 0 3px;
    }
    .image-box {
        display: flex;
        justify-content: center;
        height: 350px;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .details-text {
        padding: 24px 0 0 0;

        h3 {
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            outline-color: #121212;
            text-align: center;
            margin: 0 0 12px 0;
        }

        p {
            color: #777980;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            margin: 0 auto;
            max-width: 439px;
            text-align: center;
        }
    }
}