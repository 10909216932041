@import "../../../styles/mixins/index.scss";

.custom-pagination {
    display: flex;
    align-items: center;
    padding: 22px 24px;
    justify-content: space-between;

    span {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        padding-right: 10px;
        color: #667085;
    }

    .previous-btn {
        border: 1px solid #e0e2e7;
        background: #ffffff;
        padding: 8px 12px;
        display: flex;
        border-radius: 8px;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        box-shadow: 0px 1px 4px 0px #00000014;
        color: #667085;
        gap: 8px;
    }

    .right-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .pagination-box:last-child {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .pagination-box {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 1px solid #f0f1f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #858d9d;
        transition: 0.3s ease-in-out;

        &:hover {
            background: #f8f9fb;
            color: #1d1f2c;

            svg {
                path {
                    color: #1d1f2c;
                }
            }
        }
    }
}
