.input .relative .basic-single{
    width: 100% !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined  {
    width: 100% !important;
    border: 1px solid #cccccc;
    margin-bottom: 0px !important;
}

.quotedate{
    color: #777;
    font-size: 14px;
}
.height-set{
    height: 40px !important;
    overflow: auto;
}