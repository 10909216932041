@import '../../../styles/mixins/index.scss';

.add-news-alignment {
    .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.add-news-sapcing-alignment {
    padding: 20px;

    @include breakpoint("sm-max") { 
        padding: 14px;
    }
}




.add-news-form-design {
    padding: 40px 20px;
    max-width: 1250px;

    h2 {
        @include font18;
        color: $theme-black-100;
        margin: 0;
    }

    .product-details-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    
    .grid {
        display: grid;
        grid-template-columns: repeat(4 , 1fr);
        gap: 20px;


        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
            gap: 16px;
        }

        .bottom-alignment:nth-child(3) {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
        .bottom-alignment:last-child {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .profile-info{
       top: 2px;
       align-items: center;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
}

.order-table {
    padding: 0px;
    z-index: -1;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
    .width-100{
        width: 220px !important;
    }
    .width-80{
        width: 125px !important;
    }
    .width-50{
        width: 75px !important;
    }
    .width-100{
        width: 175px !important;
    }
    .width-10{
        width: 25px !important;
    }
}

.height-box {
    height: 40px !important;
}

.select-change-design-change {
    input {
        height: auto !important;
    }
}

.grid-column-auto {
    grid-column: auto !important;

    .ant-picker {
        width: 100% !important;
    }
}

.add-order-page-design-change {
    padding: 20px;
}


