@import '../../../styles/mixins/index.scss';

::ng-deep .map-locator-modal-md {
    position: fixed;
    top: 0;
    right: 0;
    width: 564px !important;
    height: 100vh;
    background-color: #fff;
    z-index: 99999;
}
.width1{
    width: 564px !important;
}

.map-locator-modal-md .header-modal .close {
    font-size: 20px;
    color: black !important;
    margin: 0;
}
.map-locator-modal-md .header-modal {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.boldtext{
    font-weight: bold;
    margin-left: 10px;
}
.closeIcon {
    font-size: 20px;

}


  // Dispatch Form Styles
  .dispatch-form {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .dispatch-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .dispatch-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .dispatch-number {
    font-size: 16px;
    font-weight: bold;
  }
  
  .dispatch-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .left-details, .right-details {
    flex: 1;
  }
  
  .detail-row {
    margin-bottom: 8px;
    display: flex;
  }
  
  .label {
    font-weight: 500;
    min-width: 120px;
    color: #555;
  }
  
  .value {
    font-weight: normal;
  }
  
  .section-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 15px 0;
  }
  
  .lr-details, .product-details {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  

  .total-row {
    font-weight: bold;
  }
  
  .terms-conditions {
    margin-top: 20px;
  }
  
  .terms-conditions p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  
  // Timeline Styles
  .timeline-view {
    width: 350px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    max-height: 100vh;
    overflow: scroll;
  }
  
  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .timeline-header h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .add-button {
    background-color: #9e9e9e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    
    &:hover {
      background-color: #8e8e8e;
    }
  }
  
  .timeline-entries {
    padding: 0;
  }
  
  .timeline-entry {
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    
    &:last-child {
      border-bottom: none;
    }
    
    p {
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
    }
    
    .time {
      font-weight: 500;
      margin: 0 4px;
    }
  }