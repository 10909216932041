@import '../../../styles/mixins/index.scss';

.deal-table-alignment {
    padding: 20px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
    
}

.tableSize{
    width: '100%';
}

.search-input{
    border: 'none';
    width: '100%';
}

.fild{
     text-align: 'center';
      width: '100%' ;
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }

  .dealtable{
    border: 'none';
    width: '100%';
  }

// Status button styles
.status-won {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.status-lost {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.status-pending {
  background-color: #ffc107;
  color: #212529;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.status-overdue {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
}

.table-v2-design {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 12px;
      text-align: left;
    }

    thead {
      tr {
        background-color: #f8f9fa;
      }

      .search-filter {
        th {
          padding: 8px;

          .icon-text-alignment {
            position: relative;
            
            input {
              width: 100%;
              padding: 6px 30px 6px 8px;
              border: 1px solid #dee2e6;
              border-radius: 4px;
              font-size: 14px;

              &::placeholder {
                color: #6c757d;
              }
            }

            .icon-align {
              position: absolute;
              right: 8px;
              top: 50%;
              transform: translateY(-50%);
              color: #6c757d;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f8f9fa;
        }

        &:hover {
          background-color: #f2f2f2;
        }
      }
    }
  }
}

.active-btn {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: white;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  color: #333;
  text-decoration: none;
  min-width: 100px;
  justify-content: center;

  &:hover {
    background-color: #f8f9fa;
  }
}

// Status-specific styles
.status-quotation {
  .dot {
    background-color: #007bff !important; // Blue for Quotation and default status
  }
}

.status-in-progress {
  .dot {
    background-color: #ff9500 !important; // Orange for in progress
  }
}

.status-won {
  .dot {
    background-color: #28a745; // Green for Won
  }
}

.status-lost {
  .dot {
    background-color: #dc3545 !important; // Red for Lost
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}