@import '../../../styles/mixins/index.scss';

.size{
    width: 10%;
}

.crad{
    width: 6%;
}

.cradsize{
    width: 4%;
}

.table-v2-design {
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          padding: 6px 30px 6px 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &::placeholder {
            color: #999;
            font-size: 13px;
          }

          &:focus {
            outline: none;
            border-color: #4a90e2;
          }
        }

        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #666;
          
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;

  &.in-active-btn {
    opacity: 0.7;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4CAF50;
  }

  &.in-active-btn .dot {
    background-color: #9e9e9e;
  }
}
.table-v2-design table thead .search-filter th .icon-text-alignment input, .table-v2-design table thead .search-filter th .icon-text-alignment select {
  width: 30% !important;
  padding: 6px 30px 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}