/* General Styles */
.user-dashboard {
    padding: 20px;
    background-color: #f8f9fa;
}

/* Header Section */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.back-button, .edit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
}

.back-button:hover, .edit-button:hover {
    background-color: #0056b3;
}

/* Tabs */
.tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    justify-content: space-between;
}

.tab {
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    width: 25%;
    text-align: center;
}

.tab.active {
    font-weight: bold;
    // border-bottom: 3px solid #007bff;
}

/* Content Sections */
.content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}



h2 {
    margin-bottom: 15px;
    font-size: 1.2em;
    color: #333;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.info-grid div {
    font-size: 0.9em;
    color: #555;
}

.status {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.8em;
    color: white;
}

.status.active , .Activate, .Active
{
    background-color: #28a745;
    color: white;
}
.three-icon-alignment {
    display: flex;
    align-items: center;
    gap: 16px;
 
    img {
        display: block;
        cursor: pointer;
    }
}
.timeline-container {
    width: 400px;
    margin: 20px auto;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header h3 {
    font-size: 18px;
  }
  
  .add-btn {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-btn:hover {
    background: #0056b3;
  }
  
  .timeline-section {
    margin-top: 20px;
  }
  
  .section-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .timeline-item {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    font-size: 13px;
  }
.left-section{
    border-right: 1px solid #cccccc;
}
.font-14{
    font-size: 14px;
}
.font-13{
    font-size: 13px;
}
.borders{
    border-bottom: 1px dotted #cccccc;
}
