

.body{margin-top:20px;
    background:#87CEFA;
    }
    
    .card-footer-btn {
        display: flex;
        align-items: center;
        border-top-left-radius: 0!important;
        border-top-right-radius: 0!important;
    }
    .text-uppercase-bold-sm {
        text-transform: uppercase!important;
        font-weight: 500!important;
        letter-spacing: 2px!important;
        font-size: .85rem!important;
    }
    .hover-lift-light {
        transition: box-shadow .25s ease,transform .25s ease,color .25s ease,background-color .15s ease-in;
    }
    .justify-content-center {
        justify-content: center!important;
    }
    .btn-group-lg>.btn, .btn-lg {
        padding: 0.8rem 1.85rem;
        font-size: 1.1rem;
        border-radius: 0.3rem;
    }
    .btn-dark {
        color: #fff;
        background-color: #1e2e50;
        border-color: #1e2e50;
    }
    
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(30,46,80,.09);
        border-radius: 0.25rem;
        box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    }
    
    .p-5 {
        padding: 3rem!important;
    }
    .card-body {
        flex: 1 1 auto;
        padding: 1.5rem 1.5rem;
    }
    
    tbody, td, tfoot, th, thead, tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
    
    .table td, .table th {
        border-bottom: 0;
        border-top: 1px solid #edf2f9;
    }
    .table>:not(caption)>*>* {
        padding: 1rem 1rem;
        background-color: var(--bs-table-bg);
        border-bottom-width: 1px;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }
    .px-0 {
        padding-right: 0!important;
        padding-left: 0!important;
    }
    .table thead th, tbody td, tbody th {
        vertical-align: middle;
    }
    tbody, td, tfoot, th, thead, tr {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
    }
    
    .mt-5 {
        margin-top: 3rem!important;
    }
    
    .icon-circle[class*=text-] [fill]:not([fill=none]), .icon-circle[class*=text-] svg:not([fill=none]), .svg-icon[class*=text-] [fill]:not([fill=none]), .svg-icon[class*=text-] svg:not([fill=none]) {
        fill: currentColor!important;
    }
    .svg-icon>svg {
        width: 1.45rem;
        height: 1.45rem;
    }
    // Timeline container with fixed height
    .timeline-container {
        width: unset !important;
        height: 600px;
        overflow-y: auto;
        border: none !important;
        background-color: white !important;
        padding: 0px !important;
        padding-right: 10px;
        margin: 0px auto !important;


        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
    .back-order-button {
        background-color: #0D99FF !important;
        color: white;
        padding: 5px 20px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        height: 30px;
        font-size: 12px;
        font-weight: bold;
    }
    .pdf-only {
        display: none !important;  /* Hide on screen */
    }
    