.user-dropdown-container {
    .user-select {
        padding: 8px 12px;
        border: 1px solid #E5E7EB;
        border-radius: 6px;
        background: #fff;
        color: #111827;
        font-size: 14px;
        min-width: 150px;
        cursor: pointer;
        outline: none;

        &:focus {
            border-color: #2196F3;
            box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
        }

        option {
            padding: 8px;
        }
    }
} 