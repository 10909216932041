@import '../../../styles/mixins/index.scss';

.table-input-background {
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.table-input-background:focus {
  outline: none;
  border: 1px solid #ddd;
}

.w-100 {
  width: 100%;
}

.border-0 {
  border: none;
}

.table-wrapper {
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #E5E7EB;
  overflow: hidden;
}

.table-v2-design {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 16px;
      text-align: left;
      font-size: 14px;
      border-bottom: 1px solid #E5E7EB;
    }

    th {
      background: #F9FAFB;
      color: #374151;
      font-weight: 500;
    }

    td {
      color: #111827;
      background: #FFFFFF;
    }

    .checkbox-column {
      width: 48px;
      text-align: center;
    }

    .action-column {
      width: 80px;
      text-align: center;
    }

    .name-column {
      min-width: 200px;
    }

    .filter-row {
      th {
        padding: 12px 16px;
        background: #F9FAFB;

        input, select {
          width: 100%;
          padding: 8px 12px;
          border: 1px solid #D1D5DB;
          border-radius: 6px;
          font-size: 14px;
          color: #374151;
          background: #FFFFFF;

          &::placeholder {
            color: #9CA3AF;
          }

          &:focus {
            outline: none;
            border-color: #2563EB;
            box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.2);
          }
        }

        select {
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 12px center;
          padding-right: 36px;
        }
      }
    }
  }

  .status-indicator {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    
    &.active {
      background: #ECFDF3;
      color: #027A48;
      
      .status-dot {
        background: #027A48;
      }
    }
    
    &.inactive {
      background: #FEF3F2;
      color: #B42318;
      
      .status-dot {
        background: #B42318;
      }
    }

    .status-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  .edit-link {
    color: #2563EB;
    
    &:hover {
      color: #1D4ED8;
    }
  }

  .no-data {
    text-align: center;
    padding: 24px;
    color: #6B7280;
    font-style: italic;
  }
}

.pagination-wrapper {
  padding: 16px;
  border-top: 1px solid #E5E7EB;
  background: #FFFFFF;
}

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;

  &.in-active-btn {
    opacity: 0.7;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4CAF50;
  }

  &.in-active-btn .dot {
    background-color: #9e9e9e;
  }
}

.design-v2-table {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  overflow: hidden;

  .table-container {
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        padding: 16px;
        text-align: left;
        font-weight: 600;
        border-bottom: 1px solid #E5E7EB;
        font-size: 14px;
        line-height: 20px;
        color: #111827;
      }

      th {
        font-weight: 500;
        color: #374151;
        background: #F9FAFB;
      }

      .checkbox-column {
        width: 48px;
        text-align: center;
        padding: -3 16px;
      }

      .action-column {
        width: 72px;
        text-align: center;
      }

      .filter-row {
        th {
          padding: 12px 16px;
          background: #F9FAFB;

          .filter-input {
            input, select {
              width: 100%;
              padding: 8px 12px;
              border: 1px solid #D1D5DB;
              border-radius: 6px;
              font-size: 14px;
              line-height: 20px;
              color: #374151;
              background: #FFFFFF;

              &::placeholder {
                color: #9CA3AF;
              }

              &:focus {
                outline: none;
                border-color: #2563EB;
                box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.2);
              }
            }

            select {
              appearance: none;
              padding-right: 32px;
              background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: right 12px center;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: #F9FAFB;
          }
        }
      }
    }
  }
  .active-btn {
    display: flex
;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    color: black;
}

  .status-indicator {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 2px 8px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    
    &.active {
      background: #ECFDF3;
      color: #027A48;
      
      .status-dot {
        background: #027A48;
      }
    }
    
    &.inactive {
      background: #FEF3F2;
      color: #B42318;
      
      .status-dot {
        background: #B42318;
      }
    }

    .status-dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  .action-link {
    color: #2563EB;
    
    &:hover {
      color: #1D4ED8;
    }
  }

  .empty-state {
    text-align: center;
    color: #6B7280;
    font-style: italic;
    padding: 24px;
  }
}

.pagination-container {
  padding: 16px;
  border-top: 1px solid #E5E7EB;
  background: #FFFFFF;
} 
  