@import '../../../styles/mixins/index.scss';

.common-card-section-alignment {
    .order-card-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        padding: 0 0 24px 0;
    
        .order-card-grid-items {
            border: 1px solid #e0e2e7;
            background-color: #fff;
            border-radius: 8px;
            padding: 20px;
    
            .card-body-alignment {
                display: flex;
                align-items: center;
                gap: 3px;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #858d9d;
                }
                .icon-text {
                    display: flex;
                    align-items: center;
                    gap: 5px;
    
                    span {
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: #1a9882;
                    }
                }
            }
    
            .card-header-alignment {
                display: flex;
                padding: 0 0 10px 0;
                justify-content: space-between;
    
                h3 {
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 32.9px;
                    letter-spacing: 0.005em;
                    margin: 0;
                }
    
                span {
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.005em;
                    color: #777980;
                    padding: 0 0 4px 0;
                }
            }
        }
    }
    
}