@import '../../../styles/mixins/index.scss';

.table-v2-design {
  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 12px;
      text-align: left;
    }

    .search-filter {
      th {
        padding: 8px;

        .icon-text-alignment {
          position: relative;
          
          input {
            width: 100%;
            padding: 8px;
            padding-right: 30px;
            border: none;
            background: #f8f9fa;
            border-radius: 4px;

            &::placeholder {
              font-size: 0.75rem;
              color: #6c757d;
            }
          }

          .icon-align {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

// Status button styles
.Active, .Inactive {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.Active .dot {
  background-color: #28a745;
}

.Inactive .dot {
  background-color: #dc3545;
}