.terms-container {
    padding: 16px;
    background-color: #f9f9f9;
  
    .terms-content {
      display: flex;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fff;
      height: 300px;
  
      .section {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;
        text-align: center;
        border-right: 1px solid #ddd;
  
        &:last-child {
          border-right: none;
        }
  
        .section-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 8px;
          color: #333;
        }
  
        .section-message {
          font-size: 14px;
          color: #666;
          line-height: 1.5;
        }
      }
    }
  }
  