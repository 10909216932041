.urgent-class {
  color: red;
}

.routine-class {
  color: green;
}

.default-class {
  color: blue;
}

.location-page-header-alignment {
  .location-header-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:24px;

    .right-all-contnet-alignment {
      display: flex;
      align-items: center;
      gap: 20px;

      .right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        input {
          width: 136px;
          border: 1px solid #e0e2e7;
          background-color: #fff;
          height: 40px;

          font-size: 14px;
          border-radius: 8px;
          padding: 0 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: #858d9d;
        }
        button {
          padding: 9px 14px;
          border: 1px solid #e0e2e7;
          background-color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          border-radius: 8px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: #858d9d;

          &:focus {
            outline: none;
          }
        }
      }

      .checkbox-text {
        display: flex;
        align-items: center;
        gap: 6px;

        .dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: red;
        }

        input[type="checkbox"] {
          width: 16px;
          height: 16px;
          margin: 0;
          padding: 0;
          accent-color: #dc7633;
        }
        span {
          display: block;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: #667085;
        }
      }
    }
  }

  .location-grid {
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 0;
    position: relative;

    .location-grid-items {
      position: relative;
    
      .white-box-map {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 500px;
        height: 100px;
        background-color: #f5f5f5;
        // z-index: 99999;
        float: left;
      }
    }

    .menu-open {
      position: absolute;
      right: 0;
      background: #0d99ff;
      transition: .3s ease-in-out;
      width: 50px;
      height: 40px;
      z-index: 999;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: .3s ease-in-out;
      justify-content: center;

      &.menu-open-change {
        right: 320px;
        transition: .3s ease-in-out;

        svg {
          transition: .3s ease-in-out;
          transform: rotate(180deg);
        }
      }

      svg {
        width: 16px;
        height: 16px;
        transition: .3s ease-in-out;
        path {
          fill: #fff;
        }
      }
    }

    .user-list {
      background-color: #fff;
      height: calc(100vh - 160px);
      overflow: auto;
      overflow-x: hidden;

select {
  width: 100%; 
  padding: 10px 15px;
  font-size: px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background-color: #f9f9f9; 
  color: #333; 
  outline: none; 
  cursor: pointer;
  appearance: none;
  transition: all 0.3s ease-in-out; 
}


select option {
  padding: 15px; 
  font-size: 16px;
  color: #100202; 
  background-color: #fff; 
}

select:focus {
  border-color: #cb4ae2; 
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); 
}


select:hover {
  border-color: #888; 
}


select option {
  text-align: left; 
  white-space: nowrap;
  font-weight: 400; 
}


@media (max-width: 768px) {
  select {
    font-size: 13px; 
    padding: 8px 12px; 
  }
}


      .name-dot-grid {
        display: grid;
        padding: 10px;
        grid-template-columns: 12px 1fr;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: 0.3s ease-in-out;

        &:hover {
          background-color: #f5f5f5;
        }

        &:active {
          background-color: #f5f5f5;
        }

        .dot-gray {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #8888;
        }

        .dot-red {
          width:10px;
          height: 10px;
          border-radius: 50%;
          background-color: #c70b0bea;
          position: absolute;
            top: 37px;
            left: 39px;
        }

        .dot-green {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: green;
          position: absolute;
            top: 37px;
            left: 39px;
        }
       

        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.005em;
          color: #1d1f2c;
        }
      }
      h3 {
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #1d1f2c;
      }
    }
  }
}

.location-modal {
  position: fixed;
  right: 0;
  width: 320px;
  height: calc(100vh - 144px);
  z-index: 99;
  background-color: #fff;
  top: 144px;
  overflow: auto;

  &.hide {
    transform: translateX(100%);
    transition: .3s ease-in-out;
  }

  &.show {
    transform: translateX(0%);
    transition: .3s ease-in-out;
  }

  .sticky-card {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
  }

  .all-card-box {
    background-color: #f5f5f5;
    padding: 16px;

    .starting-time{
      font-size: 16px;
      color: #989898;
      font-weight: 600;
      letter-spacing: 0.005em;
      margin: 0 0 4px 0;
    }
    .new-grid {
      display: grid;
      grid-template-columns: 8px 1fr;
      gap: 16px;
      position: relative;
      margin-top: 13px;

      .bottom-sapcing {
        padding: 0 0 12px 0;
      }

      .line {
        position: relative;

        &::after {
          width: 13px !important;
          height: 13px !important;
          position: absolute;
          top: 4px 
          !important;
          left: -4px;
          background: #0D99FF;
          content: '';
          border-radius: 50%;
        }

        &::before {
          position: absolute;
          left: 1px;
          background-color: #c2bfbf;
          height: calc(100% - 20px);
          top: 12px;
          width: 2px;
          content: '';
        }
      }
    }

    h3 {
      font-size: 16px;
      color: #1d1f2c;
      font-weight: 600;
      letter-spacing: 0.005em;
      margin: 0 0 16px 0;
    }

    // .white-box {
    //   background-color: #fff;
    //   border-radius: 8px;
    //   padding: 11px;
    //   border: 1px solid #e0e0e0;
    //   gap:15px;

    //   strong {
    //     font-size: 16px;
    //     font-weight: 600;
    //     display: block;
    //     padding: 0 0 4px 0;
    //     letter-spacing: 0.005em;
    //     color: #0d99ff;
    //   }
    //   p {
    //     margin: 0;

    //     font-size: 14px;
    //     color: #4A4C56;
    //     font-weight: 500;
    //   }
    // }
    .white-box {
      background-color: #fff;
      border-radius: 8px;
      padding: 11px;
      border: 1px solid #e0e0e0;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    
    .white-box p {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
    }
    
    .time-taken {
      font-size: 12px;
      color: #888; 
    }
    

    h4 {
      display: block;
      font-size: 12px;
      color: #4A4C56;
      font-weight: 500;
      margin: 0 0 5px 0;
    }
  }

  .sub-header-alignment {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;

    .all-text-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        display: block;
        font-size: 12px;
        color: #1D1F2C;
        margin: 0;
        text-align: center;
        font-weight: 600;
      }
      span {
        display: block;
        font-size: 12px;
        color: #4A4C56;
        text-align: center;
        font-weight: 500;
      }
    }

    .title-sub-text-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0  0 12px 0;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #0D99FF;
        margin: 0;
      }

      span {
        display: block;
        font-size: 13px;
        color: #4A4C56;

      }

    }
  }

  .location-modal-header {
    background: #45b351;

    .header-four-col {
      display: grid;
      grid-template-columns: repeat(4 , 1fr);
      gap: 0;
      padding: 12px 0 16px 0;


      .items {
        padding: 0 12px;
        border-right: 1px solid #fffefe5c;
        span {
          display: block;
          font-size: 14px;

        }
        p,span {
          font-size: 12px;
          color: #fff;
          font-weight: 500;
          text-align: center;
        }
        
      }
    }

    .header-grid {
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 12px;
      padding: 16px 16px 10px 16px;

      img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #fff;
      }

      span {
        display: block;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
      }
    }
  }
}
