@import "../../../styles/mixins/index.scss";

.dashboard-three-column-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin: 24px;

    .items {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

        .items-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h3 {
                font-size: 24px;
                font-weight: 600;
                color: #2B3674;
            }

            .active-leads-button {
                button {
                    background: #FFFFFF;
                    border: 1px solid #E0E5F2;
                    border-radius: 16px;
                    padding: 8px 16px;
                    color: #2B3674;
                    font-weight: 500;
                }
            }
        }

        // Specific styling for the pie chart container
        .chart-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .items-header {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 20px;

                h3 {
                    font-size: 16px;
                    color: #2B3674;
                    font-weight: 500;
                }
            }

            .chart-wrapper {
                position: relative;
                width: 300px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

                .center-percentage {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    
                    .percentage {
                        display: block;
                        font-size: 24px;
                        font-weight: 600;
                        color: #2B3674;
                    }
                    
                    .label {
                        display: block;
                        font-size: 12px;
                        color: #707EAE;
                    }
                }
            }

            .custom-legend {
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                gap: 12px;
                margin-top: 20px;
                width: 100%;
                padding: 0 40px;
                display: flex;
                justify-content: center;

                .legend-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin: 15px 0;

                    .legend-color {
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                    }

                    .legend-label {
                        font-size: 12px;
                        color: #2B3674;
                    }

                    &.converted .legend-color {
                        background-color: #FFB547;
                    }

                    &.active .legend-color {
                        background-color: #4318FF;
                    }

                    &.disqualified .legend-color {
                        background-color: #FF5252;
                    }

                    &.new .legend-color {
                        background-color: #3ac977;
                    }
                }
            }
        }

        .conversion-insights {
            padding: 16px 0;

            .insight-header {
                color: #2B3674;
                font-size: 14px;
                margin-bottom: 20px;
                padding: 10px 16px;
            }

            .city-list {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .city-item {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    padding: 0 16px;

                    .radio-dot {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #E0E5F2;
                        border-radius: 50%;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background: #E0E5F2;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .city-info {
                        display: flex;
                        gap: 8px;
                        color: #2B3674;
                        font-size: 14px;

                        .city-name {
                            font-weight: 500;
                        }

                        .city-type {
                            color: #707EAE;
                        }
                    }
                }
            }
        }

        .geographic-section {
            .map-section {
                position: relative;
                height: 400px;
                background: #F8F9FA;
                border-radius: 16px;
                overflow: hidden;
                margin-bottom: 24px;

                .map-container {
                    width: 100%;
                    height: 100%;
                }

                .map-controls {
                    position: absolute;
                    bottom: 16px;
                    left: 16px;
                    z-index: 1;

                    .legend {
                        background: #FFFFFF;
                        border-radius: 8px;
                        padding: 8px 12px;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

                        .legend-item {
                            display: flex;
                            align-items: center;
                            margin: 4px 0;

                            .dot {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                margin-right: 8px;

                                &.active {
                                    background: #4ADE80;
                                }

                                &.inactive {
                                    background: #CBD5E1;
                                }
                            }

                            span {
                                color: #64748B;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .leads-overview-section {
                padding: 24px;
                background: #F8F9FA;
                border-radius: 16px;

                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #1E293B;
                    margin-bottom: 24px;
                }

                .percentage-circle {
                    width: 120px;
                    height: 120px;
                    margin: 0 auto 24px;
                    position: relative;
                    background: conic-gradient(#FF6B6B 0% 15.38%, transparent 15.38% 100%);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100px;
                        height: 100px;
                        background: white;
                        border-radius: 50%;
                    }

                    .percentage-value {
                        position: relative;
                        text-align: center;
                        z-index: 1;

                        .value {
                            display: block;
                            font-size: 24px;
                            font-weight: 600;
                            color: #1E293B;
                        }

                        .label {
                            display: block;
                            font-size: 14px;
                            color: #64748B;
                        }
                    }
                }

                .overview-text {
                    color: #64748B;
                    font-size: 14px;
                    margin-bottom: 24px;
                    text-align: center;
                }

                .area-breakdown {
                    .area-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 0;
                        border-bottom: 1px solid #E2E8F0;

                        &:last-child {
                            border-bottom: none;
                        }

                        .area-name {
                            color: #1E293B;
                            font-weight: 500;
                        }

                        .lead-count {
                            color: #64748B;
                        }
                    }
                }

                .select-message {
                    text-align: center;
                    color: #64748B;
                    padding: 40px 0;

                    p {
                        margin: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Marker styles
.marker {
    width: 24px;
    height: 24px;
    background: #4ADE80;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }

    &.selected {
        background: #4318FF;
    }
}

// Pulse animation
@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
}

// Popup styles
.mapboxgl-popup {
    max-width: 200px;

    .mapboxgl-popup-content {
        padding: 12px;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        h3 {
            margin: 0 0 4px;
            font-size: 14px;
            font-weight: 600;
            color: #1E293B;
        }

        p {
            margin: 0;
            font-size: 12px;
            color: #64748B;
        }
    }
}

// Map controls style
.mapboxgl-ctrl-group {
    border: none !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
    border-radius: 8px !important;
    overflow: hidden;

    button {
        width: 30px !important;
        height: 30px !important;
        
        &:hover {
            background-color: #f5f5f5 !important;
        }
    }
}

// Hide attribution
.mapboxgl-ctrl-attrib {
    display: none;
}

.save-btn,
.submit-btn,
button[type="submit"] {
  background-color: #1477e7;
  &:hover {
    background-color: darken(#1477e7, 10%);
  }
}

.custom-marker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.custom-popup {
    padding: 8px;
    text-align: center;
    
    h3 {
        margin: 0 0 4px;
        font-size: 14px;
        font-weight: 600;
    }
    
    p {
        margin: 0;
        font-size: 12px;
        color: #666;
    }
}

.map-legend {
    position: absolute;
    bottom: 16px;
    left: 16px;
    background: white;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    .legend-item {
        display: flex;
        align-items: center;
        margin: 4px 0;

        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 8px;

            &.active {
                background-color: #3ac977;
            }

            &.inactive {
                background-color: #ccc;
            }
        }
    }
}
