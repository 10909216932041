@import "../../../styles/mixins/index.scss";

.sidebar {
    background-color: #fff;
    height: 100vh;
    position: sticky;
    top: 0;
    width: 96px;
    z-index: 99999;
    border-right: 1px solid #dcdcdc;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    &.sidebar-lg {
        width: 290px !important;
        transition: 0.3s ease-in-out;
        .sidebar-header {
            .two-logo-alignment {
                img:last-child {
                    display: block;
                }
            }
        }
        .sidebar-menu {
            span {
                display: block;
            }
            .all-content-alignment {
               
    
                .animation {
                    i {
                        transition: 0.3s ease-in-out;
                        display: block;
                    }
                }
            }
        }
    }

    @include breakpoint("sm-max") {
        background-color: #ffffff;
        height: calc(100vh - 62px);
        overflow: auto;
    }

    .sidebar-header {
        padding: 20px;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: #fff;
        border-bottom: 1px solid #e0e2e7;

        .two-logo-alignment {
            display: flex;
            align-items: center;
            gap: 10px;

            img:last-child {
                display: none;
                transition: 0.3s ease-in-out;
            }
        }

        .toogle-icons-alignment {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;

            img {
                cursor: pointer;
            }
        }
    }
    .sidebar-body {
        padding: 20px 30px;
        height: 100%;
        overflow-y: auto;
        flex: 1;

        @include breakpoint("sm-max") {
            padding: 20px;
        }
    }
    .sub-menu-alignment {

        &.hide {
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            visibility: hidden;
        }

        &.show {
            max-height: 5000vh;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            visibility: visible;
        }

        a {
            color: #525252;
            cursor: pointer;
            &:hover {
                color: $theme-primary-color;
                cursor: pointer;
            }
        }
        .active {
            color: $theme-primary-color;
            cursor: pointer;
        }

        .sub-menu-design {
            padding: 14px 0;
            @include font16-medium;
            white-space: nowrap;
            font-size: 14px;
            cursor: pointer;
            color: #525252;

            img {
                max-width: 25px;
            }
        }
        span {
            display: block;
            padding: 14px 0;
            @include font16-medium;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
            color: #525252;

            transition: 0.3s ease-in-out;

            &:hover {
                color: white;
            }
        }
    }

    .sidebar-menu {
        padding: 14px 0;
        display: grid;
        grid-template-columns: 24px 1fr;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        gap: 14px;
        margin-bottom: 2px;
        position: relative;

        @include breakpoint("sm-max") {
            padding: 14px 20px;
        }

        &:hover {
            span {
                color: #0d99ff;
            }

            img {
                filter: brightness(0) saturate(100%) invert(46%) sepia(85%) 
                        saturate(2410%) hue-rotate(187deg) brightness(100%) contrast(102%);
            }

            i {
                color: #0d99ff;
            }
        }

        &:hover::after {
            content: attr(data-tooltip);
            display: none;
            position: absolute;
            left: 50%;
            top: -35px;
            transform: translateX(-50%);
            background: #333333;
            color: white;
            padding: 6px 12px;
            border-radius: 4px;
            font-size: 12px;
            white-space: nowrap;
            z-index: 1000;
            pointer-events: none;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -4px;
                transform: translateX(-50%);
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #333333;
            }
        }

        i {
            color: #525252;
            font-size: 16px;
            transition: 0.3s ease-in-out;
        }

        img {
            width: 25px;
        }

        .all-content-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .toogle-class {
                i {
                    transform: rotate(180deg);
                }
            }

            .animation {
                i {
                    transition: 0.3s ease-in-out;
                    display: none;
                }
            }
        }

        span {
            display: none;
            @include font16-medium;
            color: #525252;
            font-weight: 600;
            font-size: 14px;
            transition: 0.3s ease-in-out;
            white-space: nowrap;
            text-decoration: none;
        }
    }

    &:not(.sidebar-lg) {
        .sidebar-menu:hover::after {
            display: block;
        }

        .sub-menu-alignment .sidebar-menu:hover::after {
            display: none;
        }
    }

    &.sidebar-lg {
        .sidebar-menu:hover::after {
            display: none;
        }
    }

    .sub-menu-alignment {
        .sub-menu-design {
            &:hover {
                color: #0d99ff;
            }
        }

        a {
            text-decoration: none;
            
            &.active .sub-menu-design {
                color: #0d99ff;
            }
        }
    }

    .sidebar-menu.active {
        span {
            color: #0d99ff;
        }

        img {
            filter: brightness(0) saturate(100%) invert(46%) sepia(85%) 
                    saturate(2410%) hue-rotate(187deg) brightness(100%) contrast(102%);
        }

        i {
            color: #0d99ff;
        }
    }
}

.width {
    width: 15px;
    height: 15px;
}

.size {
    width: 18px !important;
    height: 18px;
}

.product {
    width: 15px;
    height: 15px;
}

.lead {
    width: 15px;
    height: 15px;
}
