@import "../../styles/mixins/index.scss";

  .login-page-alignment {
    padding: 24px;
    overflow: hidden;
    height: 100vh;

    .flex-box {
        display: flex;

        .flex-box-items {
            width: 50%;

            .child-box {
                max-width: 380px;
                margin: 0 auto;

                .button-design {
                    padding: 10px 0 0 0;
                    button {
                        width: 100%;
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .forgot-password-text {
                    a {
                        display: block;
                        text-align: right;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                .text-style {
                    padding: 0 0 24px 0;
                    h2 {
                        font-size: 30px;
                        color: #000;
                        margin: 0 0 8px 0;
                        font-weight: 700;
                    }

                    p {
                        font-size: 14px;
                        line-height: 24px;
                        color: #777980;
                        font-weight: 500;
                    }
                }
            }
        }

        .flex-box-items:first-child {
            display: flex;
            height: calc(100vh - 48px);
            align-items: center;
            justify-content: center;
            padding: 20px;
        }

        .flex-box-items:last-child {
            height: calc(100vh - 48px);
            background-color: #F8F9FB;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
    }
  }