@import '../../styles/mixins/index.scss';

.priority-page-alignment {
    padding: 20px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.priority-container {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .priority-header {
    margin-bottom: 16px;
  
    h2 {
      font-size: 24px;
      margin: 0;
    }
  }
  
  .tabs {
    display: flex;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 16px;
    
  }
  
  .tab {
    padding: 12px 20px;
    cursor: pointer;
    color: #6b6b6b;
    font-size: 16px;
    text-align: center;
    flex: 1;
    background-color: #f9f9f9;
    border: none;
    transition: background-color 0.3s, color 0.3s;
  
    &.active {
      font-weight: bold;
      border-top: 3px solid #007bff;
    }
  
    &.inactive {
      background-color: #e8e8e8;
    }
  
    &:hover {
      // background-color: #e8e8e8;
    }
  }
  
  
  .tab-content {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  