.tab-design {
    border-bottom: 1px solid $theme-border-color;
    button {
        padding: 8px 20px;
        @include font12;
        font-weight: 500;
        border-radius: 4px 4px 0 0;
        border: none;
        cursor: pointer;
        background-color: transparent;
        color: $theme-primary-color;
        transition: .3s ease-in-out;

        &.active {
            background-color: $theme-primary-color;
            color: $theme-white;
        }
    }
}