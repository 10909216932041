@import "../../../styles/mixins/index.scss";

.status-box-design {
    border: 1px solid #e0e2e7;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.0784313725);
    background-color: #fff;
    border-radius: 8px;
    padding: 24px;

    .box-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 14px 0;
        h3 {
            @include font18;
            font-weight: 600;
            color: #1d1f2c;
        }

        button {
            border: none;
            cursor: pointer;
            padding: 4px 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #4a4c56;
            background: #f0f1f3;
            border-radius: 8px;
        }
    }
}
