

.follow-card-main-box {
    max-height: 600px;
    scrollbar-width: none;
    overflow: auto;
    .follow-card-box:last-child {
        margin: 0;
    }
    .follow-card-box {
        border: 1px solid #e0e2e7;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px;
        margin: 0 0 12px 0;
    
        .icons-text-alignment {
            display: flex;
            align-items: center;
            gap: 6px;
    
            &.bottom-alignment-space {
                padding: 0 0 12px 0;
            }
    
            span {
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.01em;
                color: #525252;
            }
        }
    
        .follow-card-header {
            display: flex;
            padding: 0 0 12px 0;
            align-items: center;
            justify-content: space-between;
            .follow-left {
                display: flex;
                align-items: center;
                gap: 12px;
    
                .profile-img {
                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
    
                p {
                    font-size: 16px;
                    padding: 0 0 6px 0;
                    font-weight: 700;
                    line-height: 19.41px;
                    letter-spacing: 0.01em;
                    color: #232323;
                }
            }
            .follow-right {
                display: flex;
                align-items: center;
                gap: 12px;
    
                img {
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }
    
}