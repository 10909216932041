@import '../../../styles/mixins/index.scss';

.breadcumbs-alignment {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        color: #667085;
font-size: 14px;
font-weight: 500;
line-height: 20px;
cursor: pointer;

letter-spacing: 0.005em;
&.active-class {
    color: #0D99FF;

}
    }
}