@import '../../../styles/mixins/index.scss';

.table-v2-design {
  padding: 0px;
  z-index: -1;

  @include breakpoint("sm-max") {
    padding: 16px;
  }

  .search-filter {
    .icon-text-alignment {
      position: relative;
      
      input {
        width: 100%;
        padding: 8px;
        padding-right: 30px;
        border: none;
        background-color: #f5f5f5;
      }

      .icon-align {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// .highlight {
//   font-weight: bold;
//   color: #df8393;
// }

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status styles
.open .dot {
  background-color: #008000;
}

.cancelled .dot {
  background-color: #FF0000;
}

.Pending .dot {
  background-color: #FFA500;
}

.font-color {
  color: #000000;
}

.expense-table-alignment {
    padding: 0px;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }

  .tableSize{
    width: 100%;
  }
// .status {
//     padding: 5px 10px;
//     border: none;
//     border-radius: 3px;
//     cursor: pointer;
//   }
  
//   .status-approve {
//     background-color: green;
//     color: white;
//   }
  
//   .status-reject {
//     background-color: red;
//     color: white;
    
   
//   }
  
//   .status-pending {
//     background-color: yellow;
//     color: black;
//   }
  