@import '../../../styles/mixins/index.scss';

.add-news-alignment {
    .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.add-news-sapcing-alignment {
    padding: 20px;

    @include breakpoint("sm-max") {
        padding: 14px;
    }
}



.add-news-form-design {
    padding: 40px 20px;
    max-width: 1250px;

    h2 {
        @include font18;
        color: $theme-black-100;
        margin: 0;
    }

    .product-details-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    
    .grid {
        display: grid;
        grid-template-columns: repeat(4 , 1fr);
        gap: 20px;


        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
            gap: 16px;
        }

        .bottom-alignment:nth-child(3) {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
        .bottom-alignment:last-child {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
}

.selectBox {
    width: 70px !important;
    height: 40px !important;
}

.order-table {
    padding: 20px;
    z-index: -1;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
    .width-100{
        width: 220px !important;
    }
    .width-80{
        width: 125px !important;
    }
    .width-50{
        width: 75px !important;
    }
    .width-100{
        width: 175px !important;
    }
    .width-10{
        width: 25px !important;
    }
}

.height-box {
    height: 40px !important;
}

.select-change-design-change {
    input {
        height: auto !important;
    }
}

.grid-column-auto {
    grid-column: auto !important;

    .ant-picker {
        width: 100% !important;
    }
}

.add-order-page-design-change {
    padding: 20px;
}
.height-set{
    height: 40px !important;
    overflow:auto;
}

.error-red{
    color: red;
    font-size: 14px;

}
button {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.005em;
    background: #0d99ff;
    border-radius: 8px;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;

    &.light-button {
        background: transparent;
        border: 1px solid #C2C6CE;
        color: #1D1F2C;

    }
}
.font-size{
    font-size: 14px !important;
}

.tabledesigns table tbody tr td{
    border: 1px solid #dee2e6;
  }