.active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    color: #666;

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;

        &.Rejected {
            background-color: #ff4d4f !important; // red for rejected
            width: 8px !important;
            height: 8px !important;
        }

        &.pending {
            background-color: #FFA500 !important; // orange for pending
            width: 8px !important;
            height: 8px !important;
        }

        &.Pending {
            background-color: #FFA500 !important; // orange for pending
            width: 8px !important;
            height: 8px !important;
        }

        &.Cancelled {
            background-color: #FF0000 !important; // red for cancelled
            width: 8px !important;
            height: 8px !important;
        }

        &.Close {
            background-color: #000000 !important; // black for closed
            width: 8px !important;
            height: 8px !important;
        }
    }
}

.table-v2-design {
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          padding: 6px 30px 6px 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &::placeholder {
            color: #999;
            font-size: 13px;
          }

          &:focus {
            outline: none;
            border-color: #4a90e2;
          }
        }

        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #666;
          
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .date-range-picker-wrapper {
    .ant-picker {
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      
      &:hover {
        border-color: #40a9ff;
      }
      
      &.ant-picker-focused {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }
  }
}

.font-color {
  color: #000000;
}

.ant-picker-dropdown {
  z-index: 1050;
}
.select-wrapper{
  width: 154px !important;
}