@import '../../../styles/mixins/index.scss';

.table-v2-design {
  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 12px;
      text-align: left;
    }

    .search-filter {
      th {
        padding: 8px;
        
        .icon-text-alignment {
          position: relative;
          
          input {
            width: 100%;
            padding: 6px 30px 6px 8px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            
            &::placeholder {
              font-size: 12px;
              color: #666;
            }
          }

          .icon-align {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

// Reset any existing status button styles
.Approved, .Rejected, .Pending {
  background-color: transparent !important;
  color: inherit !important;
}

// Status button styles with dots
.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent !important;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  color: inherit;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status dot colors
.Approved .dot {
  background-color: #008000 ;  // green for approved
}

.Rejected .dot {
  background-color: #FF0000 !important;  // red for rejected
}

.Pending .dot {
  background-color: #FFA500 !important;  // orange for pending
}

// Leave weight dot color - blue for all types
.leave-weight .dot {
  background-color: #007bff !important;  // blue for all leave weights
}

// Override any other background colors
button.active-btn {
  background-color: transparent !important;
}
