.pipeline-container {
  padding: 20px;
  
}

.pipeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pipeline-header h4 {
  margin: 0;
}

.pipeline-steps {
  display: flex;  
  align-items: center;
  // margin-top: 20px;
}

.step {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px 20px;
  // border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  // margin-right: 10px;
  white-space: nowrap;
}

.step.completed {
  background-color: #5cb85c;
  /* Green for completed steps */
  width: 100%;
}

.step.incomplete {
  background-color: #d3d3d3;
  /* Grey for incomplete steps */
  width: 100%;
}

.arrow {
  margin-left: 10px;
  font-size: 20px;
  color: #000;
}

.step.lost {
  background-color: #ff0000a8;
  /* Red for LOST step */
}

.pipeline-lost .step {
  background-color: #ff0000a8 !important;
  /* Full pipeline red when LOST */
  width: 100%;
}

.arrow-type-button {
  padding: 20px 0;

  ul {
    display: flex;
    padding: 0;
    margin: 0;

    li:first-child {
      border-radius: 500px 0 0 500px;
    }

    li:last-child {
      border-radius: 0 500px 500px 0;
    }

    li {
      flex: 1;
      list-style: none;
      padding: 0 10px;
      background: #ccc;
      text-align: center;
      position: relative;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active-class {
        background: #5cb85c;
        color: #fff;

        &::before {
          background: #5cb85c;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 23px;
        width: 23px;
        background: transparent;
        top: 4px;
        right: -10px;
        border-right: 2px solid #fbfbfb;
        border-top: 2px solid #fbfbfb;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 10;
      }
    }
  }

}

.arrow-type-button {
  padding: 20px 0;

  ul {
    display: flex;
    padding: 0;
    margin: 0;

    li:first-child {
      border-radius: 500px 0 0 500px;
    }

    li:last-child {
      border-radius: 0 500px 500px 0;
    }

    // li {
    //   flex: 1;
    //   list-style: none;
    //   padding: 0 10px;
    //   background: #ccc;
    //   text-align: center;
    //   position: relative;
    //   height: 30px;
    //   line-height: 30px;
    //   cursor: pointer;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;

    //   &.active-class {
    //     background: #5cb85c;
    //     color: #fff;

    //     &::before {
    //       background: #5cb85c;
    //     }
    //   }

    //   &::before {
    //     content: "";
    //     position: absolute;
    //     height: 22px;
    //     width: 22px;
    //     background: transparent;
    //     top: 4px;
    //     right: -10px;
    //     border-right: 2px solid #fbfbfb;
    //     border-top: 2px solid #fbfbfb;
    //     -webkit-transform: rotate(45deg);
    //     -ms-transform: rotate(45deg);
    //     -o-transform: rotate(45deg);
    //     transform: rotate(45deg);
    //     z-index: 10;
    //   }
    // }
    li:last-child {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    li {
      flex: 1;
      list-style: none;
      padding: 0 10px;
      background: #ccc;
      text-align: center;
      position: relative;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.active-class {
        background: #5cb85c;
        color: #fff;

        &::before {
          background: #5cb85c;
        }
      }

      &.lost-class {
        background: red;
        color: #fff;

        &::before {
          background: red;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 22px;
        width: 22px;
        background: transparent;
        top: 4px;
        right: -10px;
        border-right: 2px solid #fbfbfb;
        border-top: 2px solid #fbfbfb;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 10;
      }
    }

  }

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-width: 100px;
  z-index: 1;
}

.dropdown-menu button {
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: black;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.dropdown span {
  padding: 10px;
  cursor: pointer;
}

.add-button{
          margin-left: 113px;
          padding: 6px 12px;
          font-size: 14px;
          text-transform: uppercase;
          background-color: #eeeeee;
          border-radius: 4px;
          cursor: pointer ;
          color:black;
          position: 'relative',
}
.dropdown-custom {
  position: absolute;
  margin-top: 2px;
  margin-left: 154px;
  width: 104px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.dropdown-item{
  padding: 8px 12px;
  font-size: 14px;
  cursor: 'pointer';
  color: #333;
  white-space: nowrap;
}


.input input {
  height: 40px !important;
  border-radius: 4px !important;
}

.ant-picker-outlined {
  height: 40px !important;
}

.dispatch-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #fff;
  
  &__title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: #111827;
  }
  
  &__actions {
    display: flex;
    gap: 12px;
  }
  
  &__button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, border-color 0.2s;
    
    &--secondary {
      background-color: #fff;
      border: 1px solid #d1d5db;
      color: #4b5563;
      
      &:hover {
        background-color: #f9fafb;
      }
    }
    
    &--primary {
      background-color: #1477e7;
      border: 1px solid #1477e7;
      color: #fff;
      
      &:hover {
        background-color: #1477e7;
        border-color: #1477e7;
      }
    }
  }
  
  &__icon {
    display: inline-block;
  }
}

.container {
  
  padding: 20px;
}
   .dispatch-timeline-container {
    display: flex;
    gap: 20px;
    
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  
  // Dispatch Form Styles
  .dispatch-form {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .dispatch-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .dispatch-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .dispatch-number {
    font-size: 16px;
    font-weight: bold;
  }
  
  .dispatch-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .left-details, .right-details {
    flex: 1;
  }
  
  .detail-row {
    margin-bottom: 8px;
    display: flex;
  }
  
  .label {
    font-weight: 500;
    min-width: 120px;
    color: #555;
  }
  
  .value {
    font-weight: normal;
  }
  
  .section-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 15px 0;
  }
  
  .lr-details, .product-details {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #e0e0e0;
    padding: 8px 12px;
    text-align: left;
  }
  
  .product-table th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  .product-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    
    &.black {
      background-color: #000;
    }
  }
  
  .total-row {
    font-weight: bold;
  }
  
  .terms-conditions {
    margin-top: 20px;
  }
  
  .terms-conditions p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  
  // Timeline Styles
  .timeline-view {
    width: 350px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    max-height: 100vh;
    overflow: scroll;
  }
  
  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .timeline-header h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .add-button {
    background-color: #9e9e9e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    
    &:hover {
      background-color: #8e8e8e;
    }
  }
  
  .timeline-entries {
    padding: 0;
  }
  
  .timeline-entry {
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    
    &:last-child {
      border-bottom: none;
    }
    
    p {
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
    }
    
    .time {
      font-weight: 500;
      margin: 0 4px;
    }
  }