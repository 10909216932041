@import "../../../styles/mixins/index.scss";

.input {
    &.color-change {
        label {
            color: $theme-black-100;
            font-size: 12px;
        }

        input {
            font-size: 12px;
        }

        ::placeholder {
            font-size: 12px;
        }
    }
    &.required:after {
        content: "";
        color: red;
    }
    label {
        @include font14;
        font-weight: 400;
        display: grid;
        color: $theme-gray-700;
    }
    .relative {
        position: relative;

        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            color: $theme-gray-700;
            cursor: pointer;
            right: 14px;
        }
        .basic-single {
            width: 13rem;
        }
    }

    // input {
    //     width: 100%;
    //     height: 40px;
    //     background-color: #fff;
    //     border: 1px solid $theme-input-border-color;
    //     @include font14;
    //     color: $theme-black-text-color;
    //     padding: 0 12px;
    //     transition: .3s ease-in-out;
    //     border-radius: 4px;

    //     &:focus {
    //         outline: none;
    //         border: 1px solid $theme-primary-color;
    //     }
    // }
}

.input-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: #777980;
    display: block;
    padding: 0 0 6px 0;
    margin: 0;
}
.css-1nmdiq5-menu {
    z-index: 100 !important;
}
