.signup-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Add spacing between container elements */
}

.header {
  background-color: #007acc;
  color: white;
  padding: 15px 20px; /* Increased padding for better spacing */
  text-align: center;
  width: 100%;
}

.header .logo {
  color: white;
  font-size: 24px;
  text-decoration: none;
}

.main-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin: 30px auto; /* Add more margin for spacing */
  // padding: 20px;
}

.welcome-section {
  text-align: center;
  margin-bottom: 30px; /* Increased spacing below the section */
}
.speace {
  margin-right: 13px;
}
.brand {
  color: #007acc;
}
.logaName {
  position: absolute; /* Keeps it positioned based on its parent */
  top: 20px; /* Adjusts vertical position */
  left: 30px; /* Adjusts horizontal position */
  font-size: 25px; /* Sets font size */
  color: #007acc; /* Sets font color */
  margin: 0; /* Removes extra space around the element */
  padding: 0; /* Removes extra padding inside the element */
  display: inline-flex; /* Ensures the span takes up minimal space */
  align-items: center; /* Centers items vertically */
}

.devices-section {
  display: flex;
}

.device-image {
  // max-width: 150px;
  display: flex;
  align-items: center;
}

.signup-section {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
  width: 600px;
  height: 972.5px;
  padding: 60px;
  background-color: #0073b7;
}

.signup-section h2 {
  text-align: left;
  font-size: 33px;
  margin-top: 60px;
  margin-bottom: 30px; /* Increased margin for spacing */
  color: #fff;
}

.signup-section input,
.signup-section select {
  width: calc(100% - 20px);
  padding: 12px; /* Slightly increased padding for better usability */
  margin: 15px 0; /* Increased margin for more spacing */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-section .signup-btn {
  width: 35%;
  padding: 12px; /* Increased padding for better button usability */
  background-color: #0073b7;
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  cursor: pointer;
  font-size: 16px; /* Slightly larger font size */
  font-weight: bold;
}

.signup-section .signup-btn:hover {
  background-color: #0073b7;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: -10px; /* Reduce spacing above error text */
  margin-bottom: 10px; /* Add spacing below error text */
}

.checkbox-section {
  margin: 15px 0; /* Increased spacing around the checkbox section */
  display: flex;
  color: whitesmoke;
  align-items: center;
  font-size: 19px; /* Slightly larger font for better readability */
}
.checkbox {
  font-size: 19px;
}
.checkbox-section label {
  margin-left: 10px; /* Add spacing between checkbox and label */
}

/* Base Styles for the Container */
.middle-section {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between; /* Distribute items vertically */
  align-items: center; /* Center items horizontally */
  height: 100vh; /* Full viewport height */
  padding: 20px; /* Add some padding */
  background-color: #f4f4f4; /* Optional: Set a background color */
}

.devices-section {
  text-align: center; /* Center the text inside the section */
  margin-top: 160px; /* Add spacing from the top */
}

.devices-section p {
  font-size: 32px; /* Adjust font size for better visibility */
  // font-weight: bold;
  color: #777; /* Match the brand color */
}

.devices-section span {
  color: #0073b7; /* Highlighted color for "SalesEasy" */
  font-size: 32px; /* Slightly larger font for emphasis */
}

.devices-end {
  text-align: center; /* Center the text inside the section */
  margin-bottom: 20px; /* Add spacing from the bottom */
}

.devices-end p {
  font-size: 18px; /* Adjust font size */
  // font-weight: bold;
  color: #777; /* Gray color for the text */
}

.devices-end span {
  color: #0073b7; /* Highlighted color for "Synoris Software" */
  font-size: 18px; /* Slightly larger font for emphasis */
}
