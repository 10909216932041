/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints-min
/// @require $breakpoints-max

$breakpoints-min: (
  'sm-min': 320px,
  'md-min': 768px,
  'lg-min': 1024px,
  'xl-min': 1280px,
  ) !default;

$breakpoints-max: (
  'xs-max': 319px,
  'sm-max': 767px,
  'md-max': 1023px,
  'lg-max': 1279px,
  ) !default;


@mixin breakpoint($breakpoint) {

  // If the key exists in the map
  @if map-has-key($breakpoints-max, $breakpoint) {

    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints-max, $breakpoint)) {
      @content;
    }
  }

  @else {
    @if map-has-key($breakpoints-min, $breakpoint) {

      // Prints a media query based on the value
      @media (min-width: map-get($breakpoints-min, $breakpoint)) {
        @content;
      }
    }

    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      +"Available breakpoints are: #{map-keys($breakpoints-min),map-keys($breakpoints-max)}.";
    }
  }
}

@mixin breakpoint-max-width($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin breakpoint-min-width($width) {
  @media (min-width: $width) {
    @content;
  }
}


@mixin spacer-14 {
  padding: 14px;
}