@import '../../../styles/mixins/index.scss';

.account-table-page-alignment {
  padding: 0px;
  z-index: -1;

  @include breakpoint("sm-max") {
    padding: 16px;
  }
}

// Search input styling
.my-custom-class input::placeholder {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: left;
  padding: 3px;
  color: gray;
}

// Updated status button styles to match order table
.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status dot colors
.Active .dot {
  background-color: #008000;  // green for active
}

.Inactive .dot {
  background-color: #FF0000;  // red for inactive
}

// Search filter styling
.search-filter {
  th {
    padding: 8px;
    
    .icon-text-alignment {
      position: relative;
      
      input {
        width: 100%;
        padding: 6px 30px 6px 8px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 14px;
        
        &::placeholder {
          color: #999;
        }
      }
      
      .icon-align {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}