@import '../../../styles/mixins/index.scss';

.add-news-alignment {
    .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.add-news-sapcing-alignment {
    padding: 20px;

    @include breakpoint("sm-max") {
        padding: 14px;
    }
}




.add-news-form-design {
    padding: 40px 20px;
    max-width: 650px;

    h2 {
        @include font18;
        color: $theme-black-100;
        margin: 0 0 30px 0;
    }

    
    .grid {
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        gap: 20px;


        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
            gap: 16px;
        }

        .bottom-alignment:nth-child(3) {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
        .bottom-alignment:last-child {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
}
.required-star {
    color: red;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 4px;
  }

.success-button { 
    background-color: #0D99FF !important;
}

.new-white-box-design {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .new-white-box-design h2 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .three-col-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  
  .price-tax-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .price-tax-label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .radio-group {
    display: flex;
    gap: 16px;
  }
  
  .radio-group label {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  input[type="radio"] {
    cursor: pointer;
  }
  
  @media (max-width: 1024px) {
    .three-col-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .three-col-grid {
      grid-template-columns: 1fr;
    }
  }

  .upload-img{
    width: 12%;
  }
  
  .page-24 {
   

    table {
      width: 100%;
      border-collapse: collapse;
  
      th, td {
        padding: 10px;
        border: 1px solid #ddd;
        text-align: left;
      }
  
      th {
        background-color: #f4f4f4;
      }
    }
  
    .required-star {
      color: red;
    }
  
    .error {
      color: red;
      font-size: 12px;
    }
  }

  .checkbox-toggle-alignment {
    display: flex;
    align-items: center;
    gap: 20px;

    span , label{
      font-weight: 500;
      position: relative;
      left: 5px;
      color: #777;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }