@import '../../../styles/mixins/index.scss';

.lead-table-alignment {
    padding: 0px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

// Status button styles
.cancelled {
  background-color: #dc3545 !important;
}

.closee {
  background-color: #000000 !important;
}

.open1 {
  background-color: #3ac977 !important;
}

.overdue {
  background-color: #FFA500 !important;
}

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// .highlight {
//   font-weight: bold;
//   color: #df8393;
// }

.background {
  background-color: #fffafa;
}

.font-color {
  color: #000000;
}

.icon-text-alignment {
  position: relative;
  width: 100%;
  
  input {
    width: 100%;
    padding: 5px;
    padding-right: 25px;
  }
  
  .icon-align {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }

  .tableSize{
    width: 100%;
  }