@import "breakpoints";
@import "typography";
@import "input";
@import "layout";
@import "../container/container.scss";
@import "../theme";
@import "../variables";
@import "../common";
@import "../table/table";
@import "../tab/tab";

.admin-layout-alignment {
    display: flex;
    background-color: #f3f2f2;

    @include breakpoint("md-max") {
        display: block;
        min-height: 100vh;
    }
    .admin-sidebar {
        width: 260px;

        @include breakpoint("md-max") {
            width: 100%;
            display: none;
        }
    }

    .children-layout-admin {
        width: calc(100% - 260px);

        @include breakpoint("md-max") {
            width: 100%;
            background-color: #f3f2f2;
        }
    }
}

.table-input-background{
    background-color: #e7e7e7;
    height: 25px;
    border: none;
    font-size: 12px;
}
.ant-picker-outlined {
    height: 30px;
    background-color: #fff;
    border: 1px solid #e0e2e7;
    @include font14;
    color: $theme-black-text-color;
    padding: 0 12px;
    transition: 0.3s ease-in-out;
    border-radius: 8px;
    width: 100%;
}
.table-input-background {
    padding: 0 12px;
}

.table-input-background::placeholder {
    font-size: 12px;
}

.child-page-alignment {
    padding: 32px;
}

.design-v2-header {
    padding: 24px 32px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e2e7;

    .design-v2-right {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    .design-v2-left {
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #000;
            margin: 0 0 12px 0;
        }

        .design-breadcumb {
            display: flex;
            align-items: center;
            gap: 12px;

            span {
                display: block;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.005em;
                color: #525252;
            }
        }
    }
}

.table-v2-design {
    box-shadow: 0px 2px 4px 0px #0000001f;
    background-color: #fff;

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            tr {
                border-bottom: 1px solid #e0e2e7;

                &.search-filter {
                    th {
                        background-color: transparent;
                        padding: 8px 6px;

                        .icon-text-alignment {
                            position: relative;

                            .icon-align {
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0;
                                display: flex;
                                align-items: center;
                            }

                            input {
                                width: 100%;
                                background-color: transparent;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 20px;
                                border: none;
                                letter-spacing: 0.005em;
                                padding-left: 20px;
                                text-align: left;
                                color: #1d1f2c;
                            }

                            ::placeholder {
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.005em;
                                text-align: left;
                                color: #bdbdbd;
                            }
                        }
                    }
                }
                th {
                    padding: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    border: none;
                    line-height: 14px;
                    background: #f8f9fb;
                    letter-spacing: 0.005em;
                    color: #1d1f2c;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #e0e2e7;

                td {
                    font-size: 14px;
                    font-weight: 600;
                    vertical-align: middle;
                    line-height: 14px;
                    border: none;
                    letter-spacing: 0.005em;
                    padding: 12px 16px;
                    color: #1d1f2c;

                    svg {
                        cursor: pointer;
                    }
                    .active-btn {
                        padding: 4px 12px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        border: 1px solid #e0e2e7;
                        background-color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 14px;
                        box-shadow: 0px 1px 4px 0px #00000014;
                        letter-spacing: 0.005em;
                        color: #525252;

                        &.in-active-btn {
                            color: red;

                            .dot {
                                background-color: red;
                            }
                        }
                        .dot {
                            background: #3ac977;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .profile-text-design {
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                    display: block;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: block;
                }

                p {
                    font-size: 12px;
                    margin: 0 0 4px 0;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0.005em;
                    color: #1d1f2c;
                }

                span {
                    display: block;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 10px;
                    letter-spacing: 0.005em;
                    color: #525252;
                }
            }
        }
    }
}

.design-v2-left-right-alignment {
    padding: 0 24px 24px 24px;
}

.add-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e2e7;
    padding: 10px 24px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.005em;
        margin: 0;
        text-align: left;
        color: #1d1f2c;
    }

    .right-button-alignment {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.add-white-box-design {
    border: 1px solid #e0e2e7;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.0784313725);
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 24px;

    h2 {
        color: #1D1F2C;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 20px 0;
    }

    .cus-col-3 {
        display: grid;
        grid-template-columns: repeat(3 ,1fr);
        gap: 20px;
    }
    .cus-col-2 {
        display: grid;
        grid-template-columns: repeat(2 ,1fr);
        gap: 20px;
    }
    .col-span2 {
        grid-column: span 2;
    }
    .spacer{
        padding: 0 0 24px 0;
    }
    .sub-col {
        display: grid;
        grid-template-columns: 1fr 80px;
        gap: 12px;
        align-items: flex-end;
    }
}
