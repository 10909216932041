@import "../../styles/mixins/index.scss";

.forgot-password {
    background-color: $theme-page-background;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
        background-color: $theme-light-backgrond-color;
        width: 300px;
        border-radius: 5px;
        box-shadow: 0 0 40px 4px #111118;

        p {
            @include font14;
            color: $theme-gray-700;
            text-align: center;
            margin: 0 0 20px 0;
        }

        .button {
            border-radius: 0 0 6px 6px;
            background-color: $theme-primary-color;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font16-medium;
            color: $theme-white;
            letter-spacing: 1px;
            text-transform: uppercase;
            cursor: pointer;
        }

        .link {
            a {
                display: block;
                @include font14;
                color: $theme-primary-color;
                text-align: right;
                cursor: pointer;
            }
        }
        .header {
            height: 100px;
            background-color: #f7f7f7;
            display: flex;
            align-items: center;
            border-radius: 5px 5px 0 0;
            justify-content: center;

            @include breakpoint("sm-max") {
                height: 60px;
            }
            h1 {
                @include font26;
                color: $theme-black-text-color;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-align: center;

                @include breakpoint("sm-max") {
                    @include font16-medium;
                    font-weight: 500;
                }
            }
        }

        .body {
            padding: 20px;
            .alignment {
                padding: 0 0 20px 0;
            }
        }
    }
}
