.toggle-container {
    display: flex;
    align-items: center;

    .toggle-label {
        margin-right: 10px;
    }
}

.upload-image-design{
    width: 100px;
}

.new-white-box-design .upload-img img {
    max-width: 80px;
    height: 80px;
    display: block;
    border-radius: 8px;
    width: 100% !important;
}