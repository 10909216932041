@import "../../styles/mixins/index.scss";
.menu-pop-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .search-header-modal-md {
        width: 650px;
        background-color: #fff;
        border-radius: 12px;

        .menu-pop-height {
            max-height: calc(100vh - 200px);
            overflow: auto;
            padding: 20px;
            h2 {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.005em;
                color: #000;
                margin: 0 0 24px 0;
            }

            .list-grid {
                display: grid;
                grid-template-columns: repeat(2 , 1fr);
                padding: 0 0 20px 0;
                gap: 20px;

                .list-grid-items {
                    padding: 14px;
                    border-radius: 8px;
                    border: 1px solid #e0e0e0;
                    font-size: 16px;
                    color: #777;
                    display: flex;
                    justify-content: center;
                    font-weight: 600;
                    transition: .3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #1477e7;
                        color: #fff;
                    }
                }
            }
        }

        .header-search-design {
            padding: 20px;
            border-bottom: 1px solid #e0e0e0;
            .relative {
                position: relative;
                .icon {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    color: $theme-gray-700;
                    cursor: pointer;
                    left: 14px;
                }
            }

            input {
                width: 100%;
                height: 50px;
                background-color: #fff;
                border: 1px solid #e0e2e7;
                @include font14;
                color: $theme-black-text-color;
                padding: 0 12px 0 40px;
                transition: 0.3s ease-in-out;
                border-radius: 8px;

                &:focus {
                    outline: none;
                    border: 1px solid $theme-primary-color;
                }
            }
        }
    }
}
