@import "../../../styles/mixins/index.scss";

.font-size{
    font-size: 13px;
}

  
  /* Add slot button */
  .add-slot-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .add-slot-btn:hover {
    background-color: #0056b3;
  }
  
 
  .form-group {
    margin-bottom: 15px;
    // text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  /* Modal buttons */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .add-btn,
  .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .add-btn {
    background-color: #007bff;
    color: white;
  }
  
  .add-btn:hover {
    background-color: #0056b3;
  }
  
  .cancel-btn {
    background-color: #ccc;
    color: #333;
  }
  
  .cancel-btn:hover {
    background-color: #aaa;
  }
  
  /* Table Styles */
  .time-slot-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .time-slot-table th,
  .time-slot-table td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px;
  }
  
  .time-slot-table th {
    background-color: #f4f4f4;
  }
  
  .time-slot-table td {
    background-color: #fff;
  }
  
.row {
  height: unset !important;
}
  /* Modal and general styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .add-btn,
  .cancel-btn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
 
  
  /* Time input */
  .time-input {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .time-input select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 60px;
    text-align: center;
  }
  
  .time-input span {
    font-size: 16px;
    font-weight: bold;
    margin: 0 5px;
  }
  
  /* Table styles */
  .time-slot-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .time-slot-table th,
  .time-slot-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .time-slot-table th {
    background-color: #f4f4f4;
  }
  
  /* Button styles */
  .save-btn,
  button[type="submit"],
  .btn-save {
    background-color: #1477e7;
    &:hover {
      background-color: darken(#1477e7, 10%);
    }
  }
  