@import '../../../styles/mixins/index.scss';
legend {
    margin-bottom: 10px;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px; /* Ensures the margin-bottom is consistent */
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}
.add-news-form-design {
    /* padding: 40px 20px; */
    max-width: 100% !important; 
}
.error-red{
    color: red;
    font-size: 14px;

}
// .date-label{
//     color: #777;
//     font-size: 14px;
// }

.remove-boxshadow .add-white-box-design {
    box-shadow: none !important;
    border: none !important;
}
.font-12{
    font-size: 12px !important;
    letter-spacing: 2px !important;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spinner {
    text-align: right;
}