@import '../../../styles/mixins/index.scss';

.area-table-alignment {
    padding: 20px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

// Add search input styling similar to order table
.icon-text-alignment {
    position: relative;
    width: 100%;
    
    input {
        width: 100%;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
    }

    .icon-align {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
}

// Status button styles
.active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        background-color: #008000;  // green for active
    }
}

.in-active-btn {
    @extend .active-btn;
    
    .dot {
        background-color: #FF0000;  // red for inactive
    }
}

.background {
    background-color: #fffafa;
}

// .highlight {
//     font-weight: bold;
//     color: #df8393;
// }