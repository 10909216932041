.priority-table-container {
    .account-table-design {
        overflow-y: scroll;
        height: 500px;
        width: 1000px;
        margin: 0 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
    }

    .priority-table {
        width: 100%;
        border-collapse: collapse;

        thead tr {
            background-color: #f2f2f2;
            border-bottom: 2px solid #ddd;
        }

        th, td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        tbody tr:hover {
            background-color: #f9f9f9;
        }
    }

    .pagination-controls {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        .pagination-button {
            margin: 0 10px;
            padding: 5px 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: #007bff;
            color: #fff;
            cursor: pointer;
            transition: background-color 0.3s;

            &.disabled {
                background-color: #ccc;
                cursor: not-allowed;
            }

            &:not(.disabled):hover {
                background-color: #0056b3;
            }
        }

        span {
            display: flex;
            align-items: center;
            font-weight: bold;
        }
    }
}

.account-table-design {
    overflow-y: scroll;
    // height: 700px;
    display: block;
    margin-left: 5px;
    margin-top: 0px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;

    table {
        width: 100%;
        border-collapse: collapse;

        th, td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        thead {
            tr {
                background-color: #f2f2f2;
                border-bottom: 2px solid #ddd;
            }
            
        }
    }
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .pagination-button {
        margin: 0 10px;
        padding: 5px 15px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

    

        &:disabled {
            // background-color: #ccc;
            color: #666;
            cursor: not-allowed;
        }
    }
}

.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 14px;
    color: #333;
  
    .total-count {
      color: #007bff;
      font-weight: bold;
      margin-right: 8px;
    }
  
    .separator {
      margin: 0 8px;
      color: #aaa;
    }
  
    .rows-per-page {
      display: flex;
      align-items: center;
      margin-right: 20px;
  
      select {
        margin: 0 5px;
        padding: 5px;
        font-size: 14px;
        // border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  
    .range-display {
      font-weight: bold;
      margin: 0 8px;
    }
  
    .pagination-button {
      background: none;
      border: none;
      color: gray;
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      margin: 0 5px;
  
      &:disabled {
        // color: #ccc;
        // background-color: none;
        cursor: not-allowed;
      }
    }
  }

  .rows-dropdown{
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
//   .total-count {
//     cursor: pointer;
//     color: blue;
//     text-decoration: underline;
  
//     &:hover {
//       color: darkblue;
//     }
//   }
  