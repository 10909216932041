// @import "../../../styles/mixins/index.scss";
@import "../../../styles/mixins/index.scss";

// .input .relative .basic-single{
//     width: 100% !important;
// }

// :where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined  {
//     width: 100% !important;
//     border: 1px solid #cccccc;
//     margin-bottom: 0px !important;
// }
.activitydate{
    color: #777;
    font-size: 14px;
}