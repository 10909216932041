.design-v2-status-card {
    padding: 0 0 20px 0;
    .card-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    
        .items {
            box-shadow: 0px 2px 4px 0px #0000001f;
            border-radius: 16px;
            background: #f8f9fb;
            border: 1px solid #e0e2e7;
    
            .items-header {
                padding: 14px 24px;
    
                p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 16.45px;
                    letter-spacing: 0.005em;
                    color: #1d1f2c;
                }
            }
    
            .items-box {
                background-color: #fff;
                border: 1px solid #e0e2e7;
                padding: 24px;
                border-radius: 16px;
    
                .text-alignment {
    
                    h3 {
                        margin: 0;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: #000;
                    }
                    p {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 12px;
                        letter-spacing: 0.005em;
                        color: #525252;
                    }
                    .icon-text {
                        display: flex;
                        padding: 12px 0 0 0;
                        align-items: center;
                        gap: 4px;
                        .increase {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 12px;
                            letter-spacing: 0.005em;
                            color: #3ac977;
                        }
                        .decrease {
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 12px;
                            letter-spacing: 0.005em;
                            color: #F04438;
                        }
                    }
                }
            }
        }
    }
}