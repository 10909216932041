.bottom-bar-design {
    padding: 12px 24px;
    border-top: 1px solid #E0E2E7;
    display: flex;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    align-items: center;
    justify-content: space-between;

    .right-button-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .left-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
        p {
            color: #4A4C56;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
        }

        button {
            border: none;
            cursor: pointer;
            color: #EB3D4D;
            background-color: #eb3d4d3b;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            padding: 4px 10px;
        }
    }
}