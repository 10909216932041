.table-design {
    overflow: auto;
    table {
        width:100%;
        border-collapse: collapse;
        thead {
            background-color: $theme-white;
            tr {
                border: 1px solid $theme-border-color;
                th {
                    padding: 8px 12px;
                    @include font12;
                    font-weight: 500;
                    text-align: left;
                    color: $theme-black-100;
                    border: 1px solid $theme-border-color;
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 2px;
                    text-align: left;
                    padding: 5px;
                    border-bottom: 2px solid #edf2f9;
         


                    @include breakpoint("sm-max") {
                        white-space: nowrap;
                    }
                }
            }
        }
        .price-group-text {
            display: block;
            width: 200px;
        }
        .whitesapce-nowrap {
            white-space: nowrap;
        }
        tbody {
            tr {
                border: 1px solid $theme-border-color;
                td {
                    padding: 8px 12px;
                    @include font12;
                    color: $theme-black-100;
                    border: 2px solid $theme-border-color;
                    padding: 5px;
                    border-bottom: 1px solid #edf2f9;

                    i {
                        color: $theme-primary-color;
                        font-size: 14px;
                        cursor: pointer;
                    }

                   
                   
                }
            }
        }
    }
}

.account-table-design {
    border: 1px solid #e0e2e7;
    border-radius: 8px;
    overflow-x: auto;
    .pagination-alignment {
        padding: 14px 24px;
    }
    table {
        width: 100%;
        border-collapse: collapse;

        input[type="checkbox"]{
            width: 19px;
            height: 19px;
            margin: 0;
            padding: 0;
        }

        svg {
            cursor: pointer;
        }

        tbody {
            tr {
                border-bottom: 1px solid #f0f1f3;
                td {
                    padding: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #1d1f2c;

                    button {
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        &.danger-button {
                            background: #fff0ea;
                            color: #f86624;
                        }

                        &.active-button {
                            background: #E9FAF7;
                            color: #1A9882;

                        }
                    }

                    .gray-text {
                        color: #667085;
                    }
                    .image-text {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        img {
                            width: 44px;
                            height: 44px;
                            object-fit: cover;
                            border-radius: 4px;
                            display: block;
                        }
                    }

                    .status {
                        background-color: #28a745 !important;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Active {
                        background-color: #28a745 !important;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }

                    .Inactive{
                        background-color: grey;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .open{
                        background-color: #28a745;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Approved{
                        background-color: #28a745;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Open{
                        background-color: #28a745;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .opeen{
                        background-color: #28a745;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Opeen{
                        background-color: #28a745;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Pending{
                        background-color: #f0ad4e;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .closee{
                        background-color: #363430;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Closee{
                        background-color: #363430;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .cancelled{
                        background-color: #d9534f;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Rejected{
                        background-color: #d9534f;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .Cancelled{
                        background-color: #d9534f;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                    .overdue{
                        background-color: #4ea4f0;
                        color:$theme-white;
                        padding: 3px 8px;
                        border: none;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 500;
                        border-radius: 8px;
                        line-height: 20px;
                        letter-spacing: 0.005em;

                        &.line-active {
                            background-color: $theme-primary-color;
                            color: $theme-white;
                        }
                    }
                }
            }
        }

        thead {
          .search-input{
            height: 38px;
          }
            tr {
                background: #f0f1f3;

                th {
                    padding: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #4a4c56;
                }
            }
        }
    }
}

