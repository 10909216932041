.active-btn {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;

  &.rejected {
    background-color: #ff4d4f;
  }
  
  &.pending {
    background-color: #faad14;
  }
  
  &.approved {
    background-color: #52c41a;
  }
  
  &.cancelled {
    background-color: #ff7875;
  }
  
  &.close {
    background-color: #1890ff;
  }
  
  &.open {
    background-color: #13c2c2;
  }
}

.table-v2-design {
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          padding: 6px 30px 6px 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &::placeholder {
            color: #999;
            font-size: 13px;
          }

          &:focus {
            outline: none;
            border-color: #4a90e2;
          }
        }

        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #666;
          
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
