.new-box-timeline {
    background: #F0F1F3;
    padding: 16px;
    border-radius: 8px;
    max-width: 450px;
    cursor: pointer;

    .center-alignment {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 20px;
            height: 20px;
            path {
                fill: #0D99FF;
            }
        }
    }

    h3 {
        font-size: 16px;
        margin: 10px 0 0 0;
        color: #0D99FF;
        font-weight: 500;
        text-align: center;
    }
}

.new-box-timeline-all-alignment {
    padding: 20px 0 0 0;
    max-width: 450px;

    .line-center {
        width: 1px;
        height: 30px;
        background-color: #e0e0e0;
        margin: 0 auto;
    }
    .new-box-timeline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        

        .right-content-alignment {
            display: flex;
            align-items: center;
            gap: 20px;

            svg {
                cursor: pointer;
            }
        }

        span {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: #222;
        }
    }
}