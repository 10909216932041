@import "../../../styles/mixins/index.scss";

.input {
    &.color-change {
        label {
            color: $theme-black-100;
            font-size: 12px;
        }

        input {
            font-size: 12px;
        }

        ::placeholder {
            font-size: 12px;
        }
    }
    .required:after {
        content: "";
        color: red;
    }
    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: block;
        margin: 0;
        display: block;
        padding: 0 0 6px 0;
        color: #777980;
    }
    .relative {
        position: relative;
        .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            color: $theme-gray-700;
            cursor: pointer;
            right: 14px;
        }
    }

    input {
        width: 100%;
        height: 30px;
        background-color: #fff;
        border: 1px solid #e0e2e7;
        @include font14;
        color: $theme-black-text-color;
        padding: 0 12px;
        transition: 0.3s ease-in-out;
        border-radius: 8px;

        &:focus {
            outline: none;
            border: 1px solid $theme-primary-color;
        }
    }
}
.textarea-design-cus {
   
   
    label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: block;
        margin: 0;
        display: block;
        padding: 0 0 6px 0;
        color: #777980;
    }
  

    textarea {
        width: 100%;
        height: 120px;
        background-color: #fff;
        border: 1px solid #e0e2e7;
        @include font14;
        color: $theme-black-text-color;
        padding: 12px;
        transition: 0.3s ease-in-out;
        border-radius: 8px;

        &:focus {
            outline: none;
            border: 1px solid $theme-primary-color;
        }
    }
}

.single-label {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.005em;
        display: block;
        margin: 0;
        display: block;
        padding: 0 0 6px 0;
        color: #777980;
}