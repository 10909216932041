.open-order-button {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: black !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.cancel-order-button {
    background-color: #D44545;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px !important;
    font-weight: bold;
}
.dispatch-order-button {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: black !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
}

.back-order-button {
    background-color: #0D99FF !important;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}
@media (min-width: 1200px) {
    .h4, h4 {
        font-size: 1.2rem;
        margin-bottom: 0rem !important;

    }
}

.h6, h6 {
    font-size: 20px;
}

.image {
    img, 
    svg {
      vertical-align: middle;
      width: 22px;
    }
  }
  
  #pdfContent {
    width: 600px; /* A4 width - margins (595.28px - 20px * 2) */
    padding: 10px; /* Optional inner padding */
    margin: auto;
    font-size: 12px; /* Adjust font size for better readability */
    box-sizing: border-box;
    line-height: 1.5; /* Improve text spacing */
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    margin-top: 10px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(30, 46, 80, .09);
    border-radius: 0.25rem;
    box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
    margin-bottom: 15px;
    
    &:first-child {
        margin-top: 5px;
    }
    
    .card-body {
        flex: 1 1 auto;
        // padding: 0.75rem 1rem;
    }
}

// Header card styles
.card-header {
    padding: 1rem 1.5rem;
    background-color: transparent;
    border-bottom: 1px solid rgba(30, 46, 80, .09);
    
    .d-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

// Button group styles
.btn-group {
    display: flex;
    gap: 0.5rem;
    
    button {
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        border-radius: 4px;
        transition: all 0.3s ease;
        
        &:hover {
            transform: translateY(-1px);
        }
    }
}

// Order details container with scrollbar
.order-details-container {
    height: 600px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

// Timeline container with fixed height
.timeline-container {
    width: unset !important;
    height: 600px;
    overflow-y: auto;
    border: none !important;
    background-color: white !important;
    padding: 0px !important;
    padding-right: 10px;
    margin: 0px auto !important;


    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

// Layout structure
.page-24 {
    padding: 1.5rem;
    
    .row {
        display: flex;
        gap: 1.5rem;
        margin: 0;
        
        .col-md-8 {
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
        }
        
        .col-md-4 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
    }
}

// Print content styles
.print-content {
    padding: 30px;
    background: white;
    min-height: 500px;
    position: relative;
}

.row {
    margin: 0 -10px;
    
    .col-md-8,
    .col-md-4 {
        padding: 0 10px;
    }
}

h5.mb-0 {
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
}
  .borderBottom{
    border-bottom: solid 1px #edf2f8;
  }