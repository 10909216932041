@import "../../../styles/mixins/index.scss";

header {
    padding: 10px 20px;
  background: #F9F9FC;

  position: sticky;
  top: 0;
  z-index: 9;
    .search-design-change {
        input {
            background-color: transparent;
            border: none;
        }
    }

    .calander-icon {
        img {
            cursor: pointer;
            display: block;
        }
    }
    .country-img {
        img {
            display: block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .icon-counter-alignment {
        position: relative;

        .counter {
            background: #0D99FF;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #fff;
font-size: 10px;
font-weight: 600;
line-height: 13.6px;
letter-spacing: 0.005em;
position: absolute;
top: -10px;
right: -10px;

        }
        img {
            display: block;
            cursor: pointer;
        }
    }
    .header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .right-content-alignment {
            display: flex;
            align-items: center;
            gap: 24px;
            .right-content {
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                    cursor: pointer;
                }
            }
            .profile-contnet-alignment {
                display: flex;
                align-items: center;
                gap: 12px;
                .right-content {
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: #07080b;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 0.005em;
                        color: #4a4c56;
                    }
                }
            }
        }
    }

    .profile-all-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
        @include breakpoint("sm-max") {
            gap: 16px;
        }
        i {
            color: $theme-primary-color;
            cursor: pointer;
            display: none;

            @include breakpoint("sm-max") {
                display: block;
            }
        }
    }

    .profile {
        position: relative;

        .img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
        }

        .profile-design {
            position: absolute;
            width: 180px;
            background-color: #fff;
            border: 1px solid #e3e6ed;
            right: 0;
            border-radius: 4px;
            z-index: 999;

            &.hide {
                max-height: 0px;
                overflow: hidden;
                transition: 0.3s ease-in-out;
                visibility: hidden;
            }

            &.show {
                max-height: 100vh;
                overflow: hidden;
                transition: 0.3s ease-in-out;
                visibility: visible;
            }

            span {
                display: block;
                @include font14;
                color: $theme-black-100;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                padding: 10px 20px;
                &:hover {
                    color: $theme-primary-color;
                }
            }
        }
    }
}

.calendar-popup {
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
  }

  .icon-counter-alignment {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .counter {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    padding: 5px;
    border-radius: 50%;
  }
  
  .notification-popup {
    position: absolute;
    top: 70px;
    right: 10px;
    width: 450px;
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .popup-content {
    margin-top: 10px;
  }
  
  