@mixin font26 {
    font-size: 18px;
    line-height: normal;
    font-weight: 400;
    font-family: $theme-primary-font;
}

@mixin font22 {
    font-size: 22px;
    line-height: normal;
    font-weight: 600;
    font-family: $theme-primary-font;
}

@mixin font24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    font-family: $theme-primary-font;
}

@mixin font18 {
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    font-family: $theme-primary-font;
}

@mixin font14 {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    font-family: $theme-primary-font;
}

@mixin font12 {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    font-family: $theme-primary-font;
}

@mixin font13-medium {
    font-size: 12px;
    line-height: normal;
    font-weight: 500;
    font-family: $theme-primary-font;
}

@mixin font16-medium {
    font-size: 16px;
    line-height: normal;
    font-weight: 400;
    font-family: $theme-primary-font;
}
