.page-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $theme-white;
    position: sticky;
    top: auto;
    z-index: 9;
    border-bottom: 1px solid $theme-border-color;

    span {
        @include font18;
        color: $theme-black-100;

        @include breakpoint("sm-max") {
            @include font14;
            font-weight: 600;
        }
    }
}

input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
    accent-color: $theme-primary-color;
}

input[type="radio"] {
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    accent-color: $theme-primary-color;
}

.add-header-design {
    display: flex;
    align-items: flex-end;
    padding: 0 0 24px 0;
    justify-content: space-between;
    .right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
        button {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            min-width: 102px;
            justify-content: center;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            background:#0d99ff;
            border-radius: 8px;
            color: #fff;
            border: 1px solid transparent;
            cursor: pointer;

            &.light-button {
                background: transparent;
                border: 1px solid #c2c6ce;
                color: #1d1f2c;
            }
        }
    }
    h2 {
        @include font24;
        color: #1d1f2c;
        margin: 0 0 8px 0;
    }
}

.page-24 {
    padding: 24px;
}

.page-main-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
}

.customer-page-main-grid {
    display: grid;
    grid-template-columns: 1fr 364px;
    gap: 24px;
}

.new-white-box-design {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    margin: 0 0 24px 0;
    border: 1px solid #e0e0e087;
  

    textarea {
        width: 100%;
        height: 140px;
        background-color: #fff;
        border: 1px solid #E0E2E7;
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        color: #000;
        padding: 12px;
        transition: 0.3s ease-in-out;
        border-radius: 8px;
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.005em;
        color: #1d1f2c;
        margin: 0 0 14px 0;
    }
    .css-z3c6am-MuiFormControl-root-MuiTextField-root {
        width: 100% !important;
    }
    
    .two-col-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
        }
    }
    .three-col-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
    .upload-img {

        img {
            max-width: 80px;
            height: 80px;
            display: block;
            border-radius: 8px;
        }
    }
    :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select, :where(.css-dev-only-do-not-override-m4timi).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
        width: 100% !important;
    }
}


.page-px-24 {
    padding: 0 24px;
}