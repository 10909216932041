@import '../../../styles/mixins/index.scss';

.add-news-alignment {
    .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.add-news-sapcing-alignment {
    padding: 20px;

    @include breakpoint("sm-max") {
        padding: 14px;
    }
}



.add-news-form-design {
    padding: 40px 20px;
    max-width: 100% !important;

    h2 {
        @include font18;
        color: $theme-black-100;
        margin: 0;
    }

    .product-details-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    
    .grid {
        display: grid;
        grid-template-columns: repeat(4 , 1fr);
        gap: 20px;


        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
            gap: 16px;
        }

        .bottom-alignment:nth-child(3) {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
        .bottom-alignment:last-child {
            grid-column:  span 2;

            @include breakpoint("sm-max") {
                grid-column: auto;
            }
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
}

.order-table {
    padding: 20px;
    z-index: -1;
    width: 100%;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
    .width-100{
        width: 220px !important;
    }
    .width-80{
        width: 125px !important;
    }
    .width-50{
        width: 75px !important;
    }
    .width-100{
        width: 175px !important;
    }
    .width-10{
        width: 25px !important;
    } 
}
.addquiry{
  width: 75px !important;

}
@media (max-width: 1748px) {
  .addquiry {
    width: 16% !important;
  }
}


.height-box {
    height: 40px !important;
}

.select-change-design-change {
    input {
        height: auto !important;
    }
}

.grid-column-auto {
    grid-column: auto !important;

    .ant-picker {
        width: 100% !important;
    }
}

.add-order-page-design-change {
    padding: 20px;
}

.height-set{
    height: 40px !important;
    overflow: auto;
}
tbody th {
  background-color: #E0E2E7 !important;
}
.table-design table tbody tr td{
  border: 1px solid #dee2e6;
}

tbody tr td, 
tbody tr th {
  vertical-align: top;
}

tbody tr td .css-1s2u09g-control, 
tbody tr td textarea,
tbody tr td input {
  margin-top: 0; 
  vertical-align: top;
}
.vartical-center{
    vertical-align: middle !important; 
}
.discount-label {
    font-weight: bold;
    font-size: 13px;
    margin-right: 2px;
    white-space: nowrap;
  }
  
  .discount-type {
    margin-left: 5px;
    font-size: 13px;

  }
  
  .base-price-text {
    font-size: 12px;
    color: #555;
    margin-top: 2px;
    font-style: italic;
  }
  
//   div {
//     // margin-bottom: 8px; 
//   }
.map-locator-modal-md {
    width: 100%;
    max-width: 600px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto;
  }
  
  .header-modals {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px !important;
    border-bottom: 1px solid #9b9999;
    background-color: #E0E2E7 !important;
  }
  
  .header-modals .close {
    font-size: 18px;
    cursor: pointer;
  }
  
  .maplocator-body {
    padding: 10px 0;
  }
  
  .allcard {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .bottom-alignment {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .bottom-alignment label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .bottom-alignment input,
  .bottom-alignment select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .bottom-alignment textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical;
    min-height: 60px;
  }
  
  .required-star {
    color: red;
  }
  
  .map-locator-modal-md .header-modal div {
    margin-left: auto;
  }
  
  .map-locator-modal-md .GlobalButton {
    background: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .map-locator-modal-md .GlobalButton:hover {
    background: #0056b3;
  }
  .boxshadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .graybutton{
    background: #2e8fda;
    color: #fff;
    font-size: 14px;
    border: none;
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 18px;
    padding: 5px 15px;
    cursor: pointer;
  }
  
  .font-size{
    font-size: 15px;
  }
  .whitesapce-nowraps{
    white-space: nowrap;
  }


.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spinner {
    text-align: right;
}

