@import '../../styles/mixins/index.scss';

.add-news-alignment {
    .two-button-alignment {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

.add-news-form-design-customer {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    margin: 0 0 24px 0;

    h2 {
font-size: 18px;
font-weight: 600;
line-height: 28px;
letter-spacing: 0.005em;
color: #1D1F2C;
margin: 0 0 14px 0;
    }

    // .bottom-alignment {
    //     padding: 0 0 20px 0;
    // }
    .two-col-grid {
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        gap: 16px;

        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
        }
    }
    .checkbox-design:last-child {
        padding: 0;
    }
    .checkbox-design {
        display: flex;
        padding: 0 0 20px 0;
        align-items: center;
        gap: 10px;
        label {
            @include font14;
            display: block;
            color: $theme-gray-700;
        }
    }
}

.add-customer-page-design-alignment {
    padding: 24px;

    .add-customer-header-alignment {
        display: flex;
        align-items: flex-end;
        padding: 0 0 24px 0;
        justify-content: space-between;
        .right-contnet-alignment {
            display: flex;
            align-items: center;
            gap: 16px;
                button {
                    // padding: 10px 16px;
                    // display: flex;
                    // align-items: center;
                    // gap: 5px;
                    // font-size: 14px;
                    // font-weight: 600;
                    // line-height: 20px;
                    // letter-spacing: 0.005em;
                    // background: #0d99ff;
                    // border-radius: 8px;
                    // color: #fff;
                    // border: 1px solid transparent;
                    // cursor: pointer;
                    padding: 10px 16px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: 14px;
                    min-width: 96px;
                    justify-content: center;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    background: #0d99ff;
                    border-radius: 8px;
                    color: #fff;
                    border: 1px solid transparent;
                    cursor: pointer;
            
                    &.light-button {
                        background: transparent;
                        border: 1px solid #C2C6CE;
                        color: #1D1F2C;
            
                    }
            }
        }
        h2 {
            @include font24;
            color: #1D1F2C;
            margin: 0 0 8px 0;
        }
    }
}

.add-customer-grid {
    display: grid;
    grid-template-columns: 1fr 264px;
    gap: 24px;
}

.details-tab-grid {
    display: grid;
    grid-template-columns: 1fr 330px;
    gap: 20px;

  

    .details-white-box-design {
        background-color: #fff;
        padding: 24px;
        max-height: calc(100vh - 273px);
        overflow: auto;
        border-radius: 12px;
        .three-box {
            padding: 24px 0 0 0;
            h2 {
                color: #1D1F2C;
                font-weight: 600;
                font-size: 18px;
                margin: 0 0 16px 0;  
            }
        }
        .sec-box {
            padding: 24px 0;
            border-bottom: 1px solid #d0d0d061;
            h2 {
                color: #1D1F2C;
                font-weight: 600;
                font-size: 18px;
                margin: 0 0 16px 0;  
            }

            .text-box {
                p {
                    font-size: 16px;
                    color: #222;
                    font-weight: 500;
                    cursor: pointer;
                    display: block;
                }

                span {
                    font-size: 14px;
                    color: #777;
                    font-weight: 400;
                    cursor: pointer;
                    display: block;
                }
            }
        }

        .first-box {
            padding: 0 0 24px 0;
            border-bottom: 1px solid #d0d0d061;
            .three-col-grid {
                display: grid;
                grid-template-columns: repeat(3 , 1fr);
                gap: 20px;
                padding: 20px 0 0 0;

                h3 {
                    color: #1D1F2C;
                    font-weight: 600;
                    font-size: 18px;
                    margin: 0 0 5px 0;  
                }
            }
            h2 {
                font-size: 28px;
                color: #1D1F2C;
                font-weight: 600;
                margin: 0 0 16px 0;
            }

            a {
                font-size: 16px;
                color: #1477e7 !important;
                font-weight: 500;
                padding: 0 0 16px 0;
                cursor: pointer;
                display: block;
            }
            p {
                font-size: 16px;
                color: #777;
                font-weight: 500;
                cursor: pointer;
                display: block;
            }
        }
    }



    .details-tab-grid-items:last-child {
        background-color: #fff;
        border-radius: 12px;


        .details-all-card-alignment {
            padding: 16px;
            max-height: calc(100vh - 273px);
            overflow: auto;

            .card-grid {
                display: grid;
                grid-template-columns: 34px 1fr;
                gap: 10px;
                padding: 0 0 16px 0;

                .icons {
                    width: 34px;
                    height: 34px;
                    border-radius: 6px;
                    background-color: #f5f5f5;
                }

                span {
                    font-size: 12px;
                    color: #777;
                    display: block;
                }

                p {
                    font-size: 14px;
                    margin: 0  0 3px 0;
                    color: #1D1F2C;
                    font-weight: 500;
                }
            }
        }
        
        .details-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-bottom: 1px solid #e0e0e0;

            h2 {
                font-size: 18px;
                margin: 0;
                line-height: normal;
                font-weight: 600;
                color: #222;
            }

            span {
                cursor: pointer;
                font-size: 14px;
                display: block;
                line-height: normal;
                font-weight: 600;
                color: #1477e7;
            }
        }

      
    }
}

.new-table-design-change {
    table {
        width: 100%;
        border-collapse: collapse;

        tbody {
            tr {
                border-bottom: 1px solid #e0e0e087;
                td {
                    padding: 10px;
                    .profile {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        img {
                            display: block;
                            width: 32px;
                            object-fit: cover;
                            height: 32px;
                            border-radius: 6px;
                        }

                        small {
                            display: block;
                            @include font12;
                            font-size: 10px;
                            font-weight: 500;
                            color: #777;
                            padding: 4px 0 0 0;
                        }
                    }

                    button {
                        padding: 6px 12px;
                        border: none;
                        color: green;
                        cursor: pointer;
                        @include font12;
                        background-color: #00800026;
                        border-radius: 4px;

                        &:focus {
                            outline: none;
                        }
                    }
                    span {
                        @include font13-medium;
                        color: #222;
                        display: block;
                    }
                }
            }
        }

        thead {
            tr {
                background-color: #f5f5f5;
                th {
                    padding: 10px;
                    @include font13-medium;
                    font-weight: 600;
                    text-align: left;
                    color: #222;
                }
            }
        }
    }
}

.target-btn-right {
    display: flex;
    padding: 0 0 20px 0;
    justify-content: flex-end;
}

.transactions-title {
    color: #1D1F2C;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 16px 0;
}
.required-star {
    color: red;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 4px;
  }
  .password-input-container {
    position: relative;
  }
  
  .eye-button {
    position: absolute;
    right: 10px; /* Position the icon inside the input field */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px; /* Icon size */
  }
  
  .eye-button i {
    color: #888; /* Icon color */
  }
  
  .input-field {
    width: 100%;
    padding-right: 35px; /* To make space for the eye button */
  }
  .adjust-checkbox{
    margin-left: 24px !important;
    display: flex;
    align-items: center;
  }
  input[type=checkbox] {
    width: 14px;
    height: 14px;
    margin: 0px 6px 0px 0px !important;
    accent-color: #1477e7;
}

.tab-designs button.active{
    background-color: transparent;
    border-bottom: 1px solid #1477e7;
    color: #1477e7;
    border-radius: unset !important;
}
.tab-designs button{
    background-color: transparent;
    color: #1477e7;
}

.customer-details {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
  
  .section {
    margin-bottom: 16px;
  }
  
  .section h2 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .section-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  
  
  .profile-info h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .profile-info .email {
    color: #6c757d;
    font-size: 14px;
  }
  
  .actions {
    display: flex;
    justify-content: space-around;
    margin: 19px 0;
  }
  
  .action-btn {
    background-color: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  
  .label {
    color: #6c757d;
    font-size: 13px;
  }
  
  .value {
    font-weight: 500;
    // font-size: 13px;
    color: black;
  }
  .fontSize{
    font-size: 17px;
  }
  
  .transactions-container{
    width: unset !important;
    height: 650px;
    overflow-y: auto;
    border: none !important;
    background-color: white !important;
    padding: 14px !important;
    padding-right: 10px;


    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
  }