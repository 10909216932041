@import '../../styles/mixins/index.scss';

.highcharts-credits {
  opacity: 0;
}

.highcharts-background {
  box-shadow: 10px 10px 18px rgba(29, 25, 25, 0.2);
  border: black;


}

.box {
  box-shadow: 5px 5px 15px 1px rgba(29, 25, 25, 0.2);


}

.dashboard-page-new-design {
  padding: 20px;

  .button-group-alignment {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
    padding-bottom: 10px;

    button {
      padding: 9px 20px;
      @include font14;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      background-color: #f5f5f5;
      cursor: pointer;
      color: #222;
      transition: .3s ease-in-out;

      &.active-button {
        background-color: #1477e7;
        color: #fff;
      }

      &:hover {
        background-color: #1477e7;
        color: #fff;
      }


      &:focus {
        outline: none;
      }
    }
  }

  .chart-btn {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .overview-grid {
    display: grid;
    grid-template-columns: 1fr 410px;
    gap: 20px;

    .overview-grid-items:last-child {

      .all-card-alignment {
        height: 504px;
        overflow: auto;

        .card-design {
          padding: 16px 20px;
          border-bottom: 1px solid #e0e0e0;
          display: grid;
          grid-template-columns: 1fr 70px;
          gap: 12px;

          .two-icon {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon-design {
              width: 25px;
              height: 25px;
              background-color: #f5f5f5;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              i {
                font-size: 12px;
              }
            }
          }

          span {
            display: block;
            @include font13-medium;
            color: #777;
          }

          .icon-text {
            display: grid;
            grid-template-columns: 20px 1fr;
            gap: 10px;
            align-items: center;
            padding: 0 0 10px 0;

            .icons {
              width: 20px;
              height: 20px;
              background-color: #222;
              border-radius: 4px;
            }

            p {
              @include font14;
              color: #222;
              font-weight: 500;
            }
          }
        }
      }

      .upcomming-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 10px 20px;


        h3 {
          @include font18;
          color: #222;
        }

        span {
          @include font13-medium;
          color: #1477e7;
          display: block;
          cursor: pointer;
        }
      }
    }

    .overview-grid-items {
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;

      .card-bottom {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0;

        .card-bottom-items {
          padding: 20px;
          border-right: 1px solid #e0e0e0;

          h3 {
            @include font16-medium;
            font-weight: 600;
            color: #222;
            margin: 0 0 8px 0;
            text-align: center;
          }

          span {
            @include font13-medium;
            color: #777;
            display: block;
            text-align: center;
          }
        }
      }

      .overview-first-card {
        padding: 20px;
        border-bottom: 1px solid #e0e0e0;

        .chart-box {
          background-color: #f5f5f5;
          height: 380px;
          border-radius: 6px;
        }

        .leds-overview {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 20px 0;

          .button-group-alignment {
            display: flex;
            align-items: center;
            gap: 12px;

            button {
              padding: 9px 20px;
              @include font14;
              font-weight: 500;
              border-radius: 6px;
              border: none;
              background-color: #f5f5f5;
              cursor: pointer;
              color: #222;
              transition: .3s ease-in-out;

              &.active-button {
                background-color: #1477e7;
                color: #fff;
              }

              &:hover {
                background-color: #1477e7;
                color: #fff;
              }


              &:focus {
                outline: none;
              }
            }
          }

          h2 {
            @include font18;
            color: #222;
          }

          .highcharts-title {
            display: none;
          }
        }
      }
    }
  }

  .chart-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;


    .chart-grid-items {
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      padding: 16px;

      .sub-grid {
        display: grid;
        grid-template-columns: 90px 1fr;
        gap: 16px;

        .sub-grid-items {
          .icons {
            width: 100px;
            height: 95px;
            border-radius: 50%;
            background-color: #f5f5f5;
          }

          .MuiChartsLegend-mark {
            display: none;
          }

          .MuiChartsLegend-root.MuiChartsLegend-column {
            display: none;
          }





          p {
            @include font18;
            font-weight: 600;
            color: #222;
            margin: 0 0 10px 0;
          }

          .icon-text-grid {
            display: grid;
            grid-template-columns: 12px 1fr;
            gap: 10px;
            align-items: center;
            padding: 0 0 8px 0;

            span {
              @include font13-medium;
              color: #777;
              font-weight: 500;
            }

            .squre-icon {
              width: 12px;
              height: 12px;
              border-radius: 4px;

              &.green {
                background-color: #008000;
              }

              &.primary {
                background-color: #1477e7;
              }

              &.black {
                background-color: #000;
              }

              &.red {
                background-color: #ff0000;
              }

              &.yellow {
                background-color: #eab412e2;
              }

              &.purple {
                background-color: rgb(184, 0, 216);
              }
            }
          }
        }
      }
    }
  }
}


.visit-details-alignment {
  .border-box {
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 8px;
    margin: 0 0 12px 0;

    .routes-border-box {
      border: 1px solid #f0f0f0;
      border-radius: 6px;
      padding: 10px;
      margin: 10px 0 0 0;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #222;
      margin: 0 0 4px 0;
    }

    p {
      font-size: 14px;
      color: #777;
      font-weight: 400;
      margin: 0;
    }
  }
}