@import '../../../styles/mixins/index.scss';

.leadIndustry-table-all-content-alignment {
    padding: 20px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.table-v2-design {
  .active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    &.Active .dot {
      background-color: #28a745;
    }

    &.Inactive .dot {
      background-color: #dc3545 !important;
    }
  }
}