@import "../../../styles/mixins/index.scss";

.dashboard-three-colum-section {
    display: grid;
    grid-template-columns: 65% 32%;
    gap: 24px;
    margin: 24px;

    .items {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 20px;

        .items-header {
            margin-bottom: 20px;
            
            h3 {
                font-size: 18px;
                font-weight: 600;
                color: #1a1a1a;
            }
        }

        .overview-content {
            .graph-container {
                margin-bottom: 20px;
                height: 300px;
                
                .graph {
                    height: 100%;
                    position: relative;

                    .graph-legend {
                        position: absolute;
                        top: -45px;
                        right: 20px;
                        display: flex;
                        gap: 16px;
                        z-index: 1;
                        background: rgba(255, 255, 255, 0.8);
                        padding: 8px;
                        border-radius: 4px;

                        .legend-item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            font-size: 14px;
                            color: #666;

                            .dot {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;

                                &.revenue {
                                    background-color: #4CAF50;
                                }

                                &.orders {
                                    background-color: #FFC107;
                                }
                            }
                        }
                    }
                }
            }

            .stats-container {
                display: flex;
                justify-content: space-between;
                padding-top: 20px;
                border-top: 1px solid #eee;

                .stat-item {
                    text-align: center;

                    h4 {
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    p {
                        color: #666;
                        font-size: 14px;
                    }
                }
            }
        }

        // Specific styling for the pie chart container
        .chart-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .items-header {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 20px;

                h3 {
                    font-size: 16px;
                    color: #2B3674;
                    font-weight: 500;
                }
            }

            .chart-wrapper {
                position: relative;
                width: 300px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

                .center-percentage {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    
                    .percentage {
                        display: block;
                        font-size: 24px;
                        font-weight: 600;
                        color: #2B3674;
                    }
                    
                    .label {
                        display: block;
                        font-size: 12px;
                        color: #707EAE;
                    }
                }
            }

            .custom-legend {
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                gap: 12px;
                margin-top: 20px;
                width: 100%;
                padding: 0 40px;
                display: flex;
                justify-content: center;

                .legend-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin: 15px 0;

                    .legend-color {
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                    }

                    .legend-label {
                        font-size: 12px;
                        color: #2B3674;
                    }

                    &.converted .legend-color {
                        background-color: #FFB547;
                    }

                    &.active .legend-color {
                        background-color: #4318FF;
                    }

                    &.disqualified .legend-color {
                        background-color: #FF5252;
                    }

                    &.new .legend-color {
                        background-color: #3ac977;
                    }
                }
            }
        }

        .conversion-insights {
            padding: 16px 0;

            .insight-header {
                color: #2B3674;
                font-size: 14px;
                margin-bottom: 20px;
                padding: 10px 16px;
            }

            .city-list {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .city-item {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    padding: 0 16px;

                    .radio-dot {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #E0E5F2;
                        border-radius: 50%;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background: #E0E5F2;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .city-info {
                        display: flex;
                        gap: 8px;
                        color: #2B3674;
                        font-size: 14px;

                        .city-name {
                            font-weight: 500;
                        }

                        .city-type {
                            color: #707EAE;
                        }
                    }
                }
            }
        }

        .top-products-content {
            padding: 20px;

            .product-item {
                margin-bottom: 20px;

                .product-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .product-image {
                        margin-right: 12px;

                        .placeholder-circle {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #f0f0f0;
                        }
                    }

                    .product-details {
                        flex: 1;

                        h4 {
                            margin: 0;
                            font-size: 14px;
                            color: #333;
                        }

                        .product-stats {
                            font-size: 12px;
                            color: #666;
                            margin-top: 4px;
                        }
                    }
                }

                .progress-bar {
                    height: 6px;
                    background-color: #f0f0f0;
                    border-radius: 3px;
                    overflow: hidden;

                    .progress {
                        height: 100%;
                        border-radius: 3px;
                        transition: width 0.3s ease;
                    }
                }
            }
        }

        .top-selling-content {
            padding: 16px;

            .product-item {
                margin-bottom: 24px;

                .product-info {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .product-circle {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #4CAF50;
                        margin-right: 1rem;
                    }

                    .product-details {
                        flex: 1;

                        .product-name-section {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 8px;

                            .product-name {
                                font-size: 14px;
                                color: #333;
                                font-weight: 500;
                            }

                            .product-stats {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .progress-bar {
                            height: 4px;
                            background: #eef0f2;
                            border-radius: 2px;
                            overflow: hidden;

                            .progress-bar-fill {
                                height: 100%;
                                background: #1a85ff;
                                border-radius: 2px;
                                transition: width 0.3s ease;
                            }
                        }
                    }
                }
            }

            .no-data {
                padding: 20px;
                text-align: center;
                color: #666;
                font-size: 14px;
            }
        }
    }
}

.custom-tooltip {
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .label {
        margin: 0 0 8px;
        color: #666;
        font-size: 12px;
    }

    .orders {
        margin: 4px 0;
        color: #FFC107;
        font-weight: 500;
    }

    .revenue {
        margin: 4px 0;
        color: #4CAF50;
        font-weight: 500;
    }
}

.pending-orders-content {
    padding: 1.5rem;
    
    .orders-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        
        th, td {
            padding: 1rem;
            text-align: left;
            border-bottom: 1px solid #eee;
            font-size: 14px;
            
            &:first-child {
                padding-left: 1.5rem;
            }
            
            &:last-child {
                padding-right: 1.5rem;
                text-align: center;
            }
        }
        
        th {
            font-weight: 600;
            color: #444;
            background: #f8f9fa;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.5px;
            
            &:first-child {
                border-top-left-radius: 8px;
            }
            
            &:last-child {
                border-top-right-radius: 8px;
            }
        }
        
        td {
            color: #333;
            vertical-align: middle;
            
            &:nth-child(4) { // Amount column
                font-weight: 500;
                color: #2196F3;
            }
        }
        
        tbody {
            tr {
                transition: background-color 0.2s;
                
                &:hover {
                    background-color: #f8f9fa;
                }
                
                &:last-child td {
                    border-bottom: none;
                }
            }
        }
        
        .status-pending {
            background: #fff3cd;
            color: #856404;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
        
        .edit-button {
            background: #f0f0f0;
            border: none;
            color: #666;
            cursor: pointer;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s;
            
            i {
                font-size: 14px;
            }
            
            &:hover {
                background: #e0e0e0;
                color: #333;
                transform: scale(1.05);
            }

            &.loading {
                background: #e0e0e0;
                cursor: wait;
                
                &:hover {
                    transform: none;
                }
            }
            
            .fa-spinner {
                animation: spin 1s linear infinite;
            }
        }
    }

    .pagination-container {
        margin-top: 1.5rem;
        padding: 1rem;
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        .custom-pagination {
            display: flex;
            align-items: center;
            gap: 1rem;
            
            span {
                color: #666;
                font-size: 14px;
            }
            
            .right-alignment {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                
                .previous-btn {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0.5rem 1rem;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666;
                    background: #f8f9fa;
                    border: 1px solid #eee;
                    cursor: pointer;
                    transition: all 0.2s;
                    
                    &:hover:not([style*="cursor: not-allowed"]) {
                        background: #e9ecef;
                        color: #333;
                    }
                    
                    &[style*="cursor: not-allowed"] {
                        opacity: 0.6;
                    }
                }
                
                .pagination-box {
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666;
                    cursor: pointer;
                    transition: all 0.2s;
                    
                    &:hover {
                        background: #f8f9fa;
                    }
                    
                    &.active {
                        background: #2196F3;
                        color: white;
                        font-weight: 500;
                    }
                }
                
                .pagination-ellipsis {
                    color: #666;
                    padding: 0 0.5rem;
                }
            }
        }
    }

    .no-data {
        text-align: center;
        padding: 2rem;
        color: #666;
        font-size: 14px;
        background: #f8f9fa;
        border-radius: 8px;
        margin: 1rem 0;
        
        p {
            margin: 0;
        }
    }

    .custom-pagination {
        margin-top: 1rem;
        padding: 0 1rem;
    }
    
    // Add some bottom padding to ensure pagination doesn't get cut off
    padding-bottom: 1rem;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
