@import '../../styles/mixins/index.scss';

.customer-header-alignment {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    h2 {
        @include font24;
        color: #1D1F2C;
        margin: 0 0 8px 0;
    }

    .right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.customer-page-layoutalignment {
    padding: 24px;
}