.height-set{
    height: 40px !important;
    overflow: auto;
}

.addMediaquiry{
    width: 12%;
}
@media (max-width: 1748px) {
    .addMediaquiry {
      width: 17% !important;
    }
  }