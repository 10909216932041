.table-v2-design table tbody tr td .active-btn {
    padding: 4px 12px;
    display: flex
;
    align-items: center;
    gap: 8px;
    border: 1px solid #e0e2e7;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.0784313725);
    letter-spacing: 0.005em;
    color: #000000 !important;
}
.font-color{
    color: #000000;
}

// Add new search filter styles
.search-filter {
  th {
    padding: 8px;
    background: #f5f5f5;

    .icon-text-alignment {
      position: relative;
      
      input {
        width: 100%;
        padding: 8px 32px 8px 12px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
          outline: none;
          border-color: #2196f3;
        }
      }

      .icon-align {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #757575;
      }
    }

    .select-wrapper {
      width: 100%;
      
      > div {
        width: 100%;
      }

      .css-13cymwt-control {
        min-height: 36px;
        border-color: #e0e0e0;
        background-color: white;

        &:hover {
          border-color: #2196f3;
        }
      }

      .css-t3ipsp-control {
        min-height: 36px;
        border-color: #2196f3;
        box-shadow: none;

        &:hover {
          border-color: #2196f3;
        }
      }

      .css-1nmdiq5-menu {
        z-index: 999;
      }
    }

    .date-range-picker-wrapper {
      width: 100%;

      .ant-picker {
        width: 100%;
        border-color: #e0e0e0;

        &:hover, &.ant-picker-focused {
          border-color: #2196f3;
        }
      }
    }
  }
}

// Add status styles
.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.highlight {
  font-weight: bold;
  color: #df8393;
}

.background {
  background-color: #fffafa;
}