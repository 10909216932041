.attendance-page-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .actions-container {
    border: 1px solid #e0e2e7;
    padding: 4px;
    background-color: #fff;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 6px;

    button {
        padding: 6px 12px;
        border: none;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.005em;
        background-color: transparent;
        color: #667085;
        border-radius: 8px;

        &.active-btn {
            background: #f0f1f3;
            color: #1d1f2c;
        }

        &:hover {
            background: #f0f1f3;
            color: #1d1f2c;
        }
    }
  }

  .active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
  
      &.Active {
        background-color: #008000;  // green for active
        padding: 4px 4px!important;
      }
  
      &.Inactive {
        background-color: #FF0000 !important;  // red for inactive
        padding: 4px 4px!important;
      }
    }
  }

  .active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  }

  .loading-text{
    color: #6b6868;
    font-size: 7px !important;
}

// .status {
//   display: flex;
//   align-items: center;
//   gap: 5px; /* Space between dot and text */
// }

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.present .dot {
  background-color: green;
}

.absent .dot {
  background-color: red;
}

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
  }
  
  // Status dot colors
  .Active .dot {
    background-color: #008000;  // green for active
  }
  
  .Inactive .dot {
    background-color: #FF0000;  // red for inactive
  }
  

  
  .action-button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #0056b3;
  }
  
  .content-layout {
    display: flex;
    gap: 24px;
  }
  
  .account-table-design {
    flex: 3;
  }
  
  .filter-form {
    flex: 1;
    background-color: #f9f9f9;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .filter-form h3 {
    margin-bottom: 16px;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
  }
  
  .form-group select,
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .apply-button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .apply-button:hover {
    background-color: #0056b3;
  }
  