@import '../../../styles/mixins/index.scss';

.active-order-grid {
    display: grid;
    grid-template-columns: 1fr 550px;
    gap: 20px;

    .active-order-grid-items {
        padding: 20px;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #e0e0e0;

        .MuiChartsLegend-mark {
            display: none;
        }

        .MuiChartsLegend-root.MuiChartsLegend-column {
            display: none;
        }

        .table-design-change {
            table {
                width: 100%;
                border-collapse: collapse;

                tbody {
                    tr {
                        border-bottom: 1px solid #e0e0e0;

                        progress {
                            width: 100px;
                        }

                        .image-alignment {
                            display: flex;
                            align-items: center;
                            gap: 0;

                            img {
                                display: block;
                                width: 32px;
                                object-fit: cover;
                                height: 32px;
                                border-radius: 50%;
                                margin-left: -10px;
                                border: 1px solid #e0e0e0;
                            }
                        }

                        td {
                            padding: 10px;

                            .profile {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                img {
                                    display: block;
                                    width: 32px;
                                    object-fit: cover;
                                    height: 32px;
                                    border-radius: 6px;
                                }

                                small {
                                    display: block;
                                    @include font12;
                                    font-size: 10px;
                                    font-weight: 500;
                                    color: #777;
                                    padding: 4px 0 0 0;
                                }
                            }

                            button {
                                padding: 6px 12px;
                                border: none;
                                color: green;
                                cursor: pointer;
                                @include font12;
                                background-color: #00800026;
                                border-radius: 4px;

                                &:focus {
                                    outline: none;
                                }
                            }

                            span {
                                @include font13-medium;
                                color: #222;
                                display: block;
                            }
                        }
                    }
                }

                thead {
                    tr {
                        background-color: #f5f5f5;

                        th {
                            padding: 10px;
                            @include font13-medium;
                            font-weight: 600;
                            text-align: left;
                            color: #222;
                        }
                    }
                }
            }
        }

        .card-header-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 20px 0;
            width: 100%;

            // div {
            //     width: 100%;
            // }

            span {
                @include font14;
                font-weight: 500;
                color: #1477e7;
                display: block;
            }

            h2 {
                @include font18;
                margin-bottom: 0 !important;
                color: #222;
            }

            .button-group-alignment {
                display: flex;
                align-items: center;
                gap: 12px;

                button {
                    padding: 9px 20px;
                    @include font14;
                    font-weight: 500;
                    border-radius: 6px;
                    border: none;
                    background-color: #f5f5f5;
                    cursor: pointer;
                    color: #222;
                    transition: .3s ease-in-out;

                    &.active-button {
                        background-color: #1477e7;
                        color: #fff;
                    }

                    &:hover {
                        background-color: #1477e7;
                        color: #fff;
                    }


                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .chart-details {
            display: flex;
            align-items: center;

            .sub-grid-items {
                width: 100%;
                .icons {
                    width: 130px !important;
                    height: 130px !important;
                    border-radius: 50%;
                    background-color: #f5f5f5;
                }

                .MuiChartsLegend-mark {
                    display: none;
                }

                .MuiChartsLegend-root.MuiChartsLegend-column {
                    display: none;
                }

                p {
                    @include font18;
                    font-weight: 600;
                    color: #222;
                    margin: 0 0 10px 0;
                }

                .icon-text-grid {
                    display: grid;
                    grid-template-columns: 12px 1fr;
                    gap: 12px;
                    align-items: center;
                    padding: 0 0 8px 0;

                    span {
                        // @include font13-medium;
                        color: #777;
                        font-weight: 500;
                        font-size:12px;
                        margin-bottom: 5px;
                    }

                    .squre-icon {
                        width: 20px;
                        height:20px;
                        border-radius: 4px;
                        margin-bottom: 5px;

                        &.pending {
                            background-color:#C00900;
                        }

                        &.open {
                            background-color: #1477e7;
                        }

                        &.close {
                            background-color:gray;
                        }
                        &.cancel {
                            background-color:black;
                        }


                    }
                }
            }

        }

    }
}