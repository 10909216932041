@import "../../../styles/mixins/index.scss";

.defaultwrapper {
  
    .sidebar-wrapper {
      position: fixed;
      z-index: 999999;
      top: 0;
      left: 0;
    }

    .defaultwrapper-children {
        padding-left: 96px;
    }
}
