@import '../../../styles/mixins/index.scss';

.button {
    &.outline {
        button {
            color: $theme-primary-color;
            border: 1px solid $theme-primary-color;
            background-color: transparent;
        }
    }
    button {
        padding: 8px 20px;
        @include font12;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        border: 1px solid $theme-primary-color;
        background-color: $theme-primary-color;
        color: $theme-white;
    }
}
.button-grey{
    &.outline {
        button {
            color: $theme-primary-color;
            border: 1px solid $theme-primary-color;
            background-color: transparent;
        }
    }
    button {
        padding: 8px 20px;
        @include font12;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        border: 1px solid transparent;
        background-color: #777777 !important;
        color: $theme-white;
    }
      
}
.button-red{
    &.outline {
        button {
            color: $theme-primary-color;
            border: 1px solid $theme-primary-color;
            background-color: transparent;
        }
    }
    button {
        padding: 8px 20px;
        @include font12;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        border: 1px solid transparent;
        background-color: rgb(203, 9, 9) !important;
        color: $theme-white;
    }
      
}