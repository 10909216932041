@import '../../../styles/mixins/index.scss';

.account-table-page-alignment {
    padding: 20px;
    z-index: -1;
    
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.table-v2-design {
    background: #fff;
    border-radius: 8px;
    
    table {
        width: 100%;
        border-collapse: collapse;
        
        th, td {
            padding: 12px;
            text-align: left;
        }
        
        .search-filter {
            th {
                padding: 8px 12px;
                
                .icon-text-alignment {
                    position: relative;
                    
                    input {
                        width: 100%;
                        padding: 8px;
                        padding-right: 32px;
                        border: 1px solid #e0e0e0;
                        border-radius: 4px;
                        
                        &::placeholder {
                            font-size: 0.75rem;
                            color: #666;
                        }
                    }
                    
                    .icon-align {
                        position: absolute;
                        right: 8px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

// Status button styles
.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status dot colors
.Active {
  .dot {
    background-color: #28a745;  // green for active
  }
  color: #28a745;
}

.Inactive {
  .dot {
    background-color: #FF0000 !important;  // red for inactive
  }
  color: #FF0000 !important;
}

.searchbar-design {
    position: relative;
    margin-bottom: 0;
    
    input {
        width: 100%;
        padding: 10px;
        padding-right: 40px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
    }
    
    .icon-alignment {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.pagination-alignment {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}