@import "../../styles/mixins/index.scss";

.global-button {
    button {
        padding: 10px 24px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        background: #0D99FF;
        border-radius: 8px;
        color: #fff;
        border: none;
        cursor: pointer;

        &.light-button {
            background: #F0F1F3;
            color: #1D1F2C;

        }
    }
}
