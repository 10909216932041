@import "../../../../styles/mixins/index.scss";

.searchbar-design {
    position: relative;
    width: 100%;

    .search-input-container {
        position: relative;
        display: flex;
        align-items: center;
        
        .search-input {
            width: 100%;
            padding: 8px 36px 8px 12px;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            font-size: 14px;
            transition: all 0.2s ease;
            
            &:focus {
                outline: none;
                border-color: #2196f3;
                box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
            }
            
            &::placeholder {
                color: #757575;
            }
        }
        
        .search-icon {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            color: #757575;
            pointer-events: none;
            transition: opacity 0.2s ease;
            
            &.has-value {
                opacity: 0.8;
            }
        }
    }
    
    &.focused {
        .search-icon {
            color: #2196f3;
        }
    }
}
