@import '../../../styles/mixins/index.scss';

.order-table {

    padding: 0px;
    z-index: -1;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.my-custom-class input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    color: gray;
    
    
}
.highlight1 {
    font-weight: bold;
    color: #df8393;
   
  }
  
.ant-picker-outlined{
 border-style: none;   
}
.background{
background-color: #fffafa;
}
.font-color{
    color: #000000;
}

// Order status button styles
.Closee {
  background-color: #000000 !important;  // gray background
  // color: white;
  // padding: 6px 12px;
  // border: none;
  // border-radius: 4px;
}

.Pending {
  background-color: #FFA500 !important;  // orange background
  // color: white;
  // padding: 6px 12px;
  // border: none;
  // border-radius: 4px;
}

.Cancelled {
  background-color: #FF0000 !important;  // red background
  // color: white;
  // padding: 6px 12px;
  // border: none;
  // border-radius: 4px;
}


.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

// Status dot colors
.Closee .dot {
  background-color: #000000;  // black for closed
}

.Open .dot {
  background-color: #008000;  // green for open
}

.Pending .dot {
  background-color: #FFA500;  // orange for pending
}

.Cancelled .dot {
  background-color: #FF0000;  // red for cancelled
}

// Add these styles to your existing SCSS file
.search-filter {
  th {
    padding: 8px;
    
    .icon-text-alignment {
      position: relative;
      display: flex;
      align-items: center;

      input {
        width: 100%;
        padding: 6px 30px 6px 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;

        &::placeholder {
          color: #999;
          font-size: 13px;
        }

        &:focus {
          outline: none;
          border-color: #4a90e2;
        }
      }

      .icon-align {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        color: #666;
        
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.table-v2-design {
  .search-filter {
    // .select-wrapper {
    //   width: 100%;
      
    //   .ant-picker {
    //     width: 100%;
    //     border: 1px solid #d9d9d9;
    //     border-radius: 4px;
    //     padding: 4px 11px;
        
    //     &:hover {
    //       border-color: #40a9ff;
    //     }
        
    //     &.ant-picker-focused {
    //       border-color: #40a9ff;
    //       box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    //     }
    //   }
    // }

    .date-picker-wrapper {
      width: 100%;
      
      :global {
        .ant-picker {
          width: 100%;
          height: 32px;
          padding: 4px 11px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          
          &:hover {
            border-color: #4a90e2;
          }
          
          &.ant-picker-focused {
            border-color: #4a90e2;
            box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
          }

          .ant-picker-input {
            input {
              font-size: 14px;
              
              &::placeholder {
                color: #999;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

// Ensure the calendar dropdown appears above other elements
.ant-picker-dropdown {
  z-index: 1050;
}

.date-range-picker-wrapper {
  .ant-picker {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    
    &:hover {
      border-color: #40a9ff;
    }
    
    &.ant-picker-focused {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }
}

.select-wrapper {
  width: 100%;
  
  .filter-select {
    &__control {
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      background: #fff;
      min-height: 40px;
      width: 100%;
    }
    
    &__placeholder {
      color: #666;
    }
    
    &__value-container {
      padding: 2px 8px;
    }
    
    &__menu {
      z-index: 2;
    }
  }
}

.icon-text-alignment {
  .select-wrapper {
    width: 100%;
  }
}