.rigt_table th {
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
}

th {
    text-align: left;
}

label {
    margin-left: 2px;
}

input[type=checkbox] {
    width: 18px;
    height: 18px;
    border: solid;
}

.permission-group-box {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
}

.permission-group-box h5 {
    margin: 0;
    font-size: 16px;
}

.permission-group-box hr {
    border: 0;
    height: 1px;
    background: #e0e0e0;
}

.form-check {
    display: flex;
    align-items: center;
}

.form-check-label {
    margin-left: 5px;
}

input[type='checkbox'] {
    margin-right: 8px;
}
