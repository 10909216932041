.primary-button-design button {
    border: none;
    cursor: pointer;
    background: #0d99ff!important;
    color: #f8f9fb;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.005em;
}