@import "../../../styles/mixins/index.scss";

.dashboard-three-colum-section {
    display: flex;
    margin: 24px;

    > div {
        display: flex;
        gap: 15px;
        width: 100%;
    }

    .items {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        width: calc(33.33% - 10px); // Equal width with gap consideration
        height: 860px; // Fixed height for all items
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

        .items-header {
            margin-bottom: 20px;
            
            h3 {
                font-size: 18px;
                font-weight: 600;
                color: #1a1a1a;
            }
        }

        .overview-content {
            height: calc(100% - 60px); // Full height minus header
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .pending-orders-content {
            height: calc(100% - 60px);
            overflow-y: auto;
        }

        .top-selling-content {
            height: calc(100% - 60px);
            overflow-y: auto;
        }

        // Adjust customer overview chart container
        .customer-overview {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            .chart-container {
                width: 230px; // Slightly smaller for better fit
                height: 255px;
                margin: 0 auto 30px;
            }

            .chart-legend {
                padding: 0 20px;
            }
        }

        .graph-container {
            margin-bottom: 20px;
            height: 300px;
            
            .graph {
                height: 100%;
                position: relative;

                .graph-legend {
                    position: absolute;
                    top: -45px;
                    right: 20px;
                    display: flex;
                    gap: 16px;
                    z-index: 1;
                    background: rgba(255, 255, 255, 0.8);
                    padding: 8px;
                    border-radius: 4px;

                    .legend-item {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-size: 14px;
                        color: #666;

                        .dot {
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;

                            &.revenue {
                                background-color: #4CAF50;
                            }

                            &.orders {
                                background-color: #FFC107;
                            }
                        }
                    }
                }
            }
        }

        .stats-container {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            border-top: 1px solid #eee;

            .stat-item {
                text-align: center;

                h4 {
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                p {
                    color: #666;
                    font-size: 14px;
                }
            }
        }

        // Specific styling for the pie chart container
        .chart-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .items-header {
                width: 100%;
                padding: 0 20px;
                margin-bottom: 20px;

                h3 {
                    font-size: 16px;
                    color: #2B3674;
                    font-weight: 500;
                }
            }

            .chart-wrapper {
                position: relative;
                width: 300px;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;

                .center-percentage {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    
                    .percentage {
                        display: block;
                        font-size: 24px;
                        font-weight: 600;
                        color: #2B3674;
                    }
                    
                    .label {
                        display: block;
                        font-size: 12px;
                        color: #707EAE;
                    }
                }
            }

            .custom-legend {
                // display: grid;
                // grid-template-columns: repeat(2, 1fr);
                gap: 12px;
                margin-top: 20px;
                width: 100%;
                padding: 0 40px;
                display: flex;
                justify-content: center;

                .legend-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin: 15px 0;

                    .legend-color {
                        width: 8px;
                        height: 8px;
                        border-radius: 2px;
                    }

                    .legend-label {
                        font-size: 12px;
                        color: #2B3674;
                    }

                    &.converted .legend-color {
                        background-color: #FFB547;
                    }

                    &.active .legend-color {
                        background-color: #4318FF;
                    }

                    &.disqualified .legend-color {
                        background-color: #FF5252;
                    }

                    &.new .legend-color {
                        background-color: #3ac977;
                    }
                }
            }
        }

        .conversion-insights {
            padding: 16px 0;

            .insight-header {
                color: #2B3674;
                font-size: 14px;
                margin-bottom: 20px;
                padding: 10px 16px;
            }

            .city-list {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .city-item {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    padding: 0 16px;

                    .radio-dot {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #E0E5F2;
                        border-radius: 50%;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background: #E0E5F2;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }

                    .city-info {
                        display: flex;
                        gap: 8px;
                        color: #2B3674;
                        font-size: 14px;

                        .city-name {
                            font-weight: 500;
                        }

                        .city-type {
                            color: #707EAE;
                        }
                    }
                }
            }
        }

        .top-products-content {
            padding: 20px;

            .product-item {
                margin-bottom: 20px;

                .product-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;

                    .product-image {
                        margin-right: 12px;

                        .placeholder-circle {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #f0f0f0;
                        }
                    }

                    .product-details {
                        flex: 1;

                        h4 {
                            margin: 0;
                            font-size: 14px;
                            color: #333;
                        }

                        .product-stats {
                            font-size: 12px;
                            color: #666;
                            margin-top: 4px;
                        }
                    }
                }

                .progress-bar {
                    height: 6px;
                    background-color: #f0f0f0;
                    border-radius: 3px;
                    overflow: hidden;

                    .progress {
                        height: 100%;
                        border-radius: 3px;
                        transition: width 0.3s ease;
                    }
                }
            }
        }

        .top-selling-content {
            padding: 16px;

            .product-item {
                margin-bottom: 24px;

                .product-info {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .product-circle {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 12px;
                        
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .product-details {
                        flex: 1;

                        .product-name-section {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 8px;

                            .product-name {
                                font-size: 14px;
                                color: #333;
                                font-weight: 500;
                            }

                            .product-stats {
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .progress-bar {
                            height: 4px;
                            background: #eef0f2;
                            border-radius: 2px;
                            overflow: hidden;

                            .progress-bar-fill {
                                height: 100%;
                                background: #1a85ff;
                                border-radius: 2px;
                                transition: width 0.3s ease;
                            }
                        }
                    }
                }
            }

            .no-data {
                padding: 20px;
                text-align: center;
                color: #666;
                font-size: 14px;
            }
        }
    }
}

.custom-tooltip {
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .label {
        margin: 0 0 8px;
        color: #666;
        font-size: 12px;
    }

    .orders {
        margin: 4px 0;
        color: #FFC107;
        font-weight: 500;
    }

    .revenue {
        margin: 4px 0;
        color: #4CAF50;
        font-weight: 500;
    }
}

.pending-orders-content {
    padding: 1.5rem;
    
    .orders-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        
        th, td {
            padding: 1rem;
            text-align: left;
            border-bottom: 1px solid #eee;
            font-size: 14px;
            
            &:first-child {
                padding-left: 1.5rem;
            }
            
            &:last-child {
                padding-right: 1.5rem;
                text-align: center;
            }
        }
        
        th {
            font-weight: 600;
            color: #444;
            background: #f8f9fa;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.5px;
            
            &:first-child {
                border-top-left-radius: 8px;
            }
            
            &:last-child {
                border-top-right-radius: 8px;
            }
        }
        
        td {
            color: #333;
            vertical-align: middle;
            
            &:nth-child(4) { // Amount column
                font-weight: 500;
                color: #2196F3;
            }
        }
        
        tbody {
            tr {
                transition: background-color 0.2s;
                
                &:hover {
                    background-color: #f8f9fa;
                }
                
                &:last-child td {
                    border-bottom: none;
                }
            }
        }
        
        .status-pending {
            background: #fff3cd;
            color: #856404;
            padding: 0.5rem 1rem;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
        
        .edit-button {
            background: #f0f0f0;
            border: none;
            color: #666;
            cursor: pointer;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s;
            
            i {
                font-size: 14px;
            }
            
            &:hover {
                background: #e0e0e0;
                color: #333;
                transform: scale(1.05);
            }

            &.loading {
                background: #e0e0e0;
                cursor: wait;
                
                &:hover {
                    transform: none;
                }
            }
            
            .fa-spinner {
                animation: spin 1s linear infinite;
            }
        }
    }

    .pagination-container {
        margin-top: 1.5rem;
        padding: 1rem;
        border-top: 1px solid #eee;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        .custom-pagination {
            display: flex;
            align-items: center;
            gap: 1rem;
            
            span {
                color: #666;
                font-size: 14px;
            }
            
            .right-alignment {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                
                .previous-btn {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0.5rem 1rem;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666;
                    background: #f8f9fa;
                    border: 1px solid #eee;
                    cursor: pointer;
                    transition: all 0.2s;
                    
                    &:hover:not([style*="cursor: not-allowed"]) {
                        background: #e9ecef;
                        color: #333;
                    }
                    
                    &[style*="cursor: not-allowed"] {
                        opacity: 0.6;
                    }
                }
                
                .pagination-box {
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #666;
                    cursor: pointer;
                    transition: all 0.2s;
                    
                    &:hover {
                        background: #f8f9fa;
                    }
                    
                    &.active {
                        background: #2196F3;
                        color: white;
                        font-weight: 500;
                    }
                }
                
                .pagination-ellipsis {
                    color: #666;
                    padding: 0 0.5rem;
                }
            }
        }
    }

    .no-data {
        text-align: center;
        padding: 2rem;
        color: #666;
        font-size: 14px;
        background: #f8f9fa;
        border-radius: 8px;
        margin: 1rem 0;
        
        p {
            margin: 0;
        }
    }
}

.top-selling-content {
    .product-item {
        margin-bottom: 1rem;
        
        .product-info {
            display: flex;
            align-items: center;
            
            .product-circle {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #4CAF50;
                margin-right: 1rem;
            }
            
            .product-details {
                flex: 1;
                
                .product-name-section {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;
                    
                    .product-name {
                        font-weight: 500;
                    }
                    
                    .product-stats {
                        color: #666;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.customer-overview {
    padding: 20px;
    
    .chart-container {
        position: relative;
        width: 200px;
        height: 200px;
        margin: 0 auto 20px;

        .chart-center-stats {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 1;
            background: white;
            padding: 10px;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);

            h2 {
                margin: 0;
                font-size: 24px;
                font-weight: bold;
                color: #333;
            }

            p {
                margin: 5px 0;
                color: #666;
                font-size: 14px;
            }

            .percentage-change {
                font-size: 12px;
                font-weight: 500;
                
                &.increase {
                    color: #4ade80;
                }
                
                &.decrease {
                    color: #ff6b6b;
                }
            }
        }
    }

    .chart-legend {
        .legend-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            justify-content: space-between;
            padding: 0 10px;
            
            .legend-color {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                margin-right: 8px;
                
                &.new { background-color: #ffd93d; }
                &.active { background-color: #4ade80; }
                &.inactive { background-color: #ff6b6b; }
            }

            .legend-label {
                flex-grow: 1;
                color: #666;
                font-size: 14px;
            }

            .legend-value {
                font-weight: 600;
                color: #333;
                min-width: 40px;
                text-align: right;
            }
        }
    }
}

// Add these styles for the top customers section
.top-customers-list {
    padding: 15px;
    
    .customer-item {
        padding: 15px;
        border-bottom: 1px solid #eee;
        
        &:last-child {
            border-bottom: none;
        }
        
        .customer-info {
            .customer-name {
                font-weight: 600;
                margin-bottom: 10px;
                color: #333;
            }
            
            .stats-container {
                .stat-row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    
                    .stat-label {
                        width: 70px;
                        color: #666;
                        font-size: 0.9em;
                    }
                    
                    .progress-container {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        
                        .progress-bar {
                            flex: 1;
                            height: 6px;
                            background-color: #f0f0f0;
                            border-radius: 3px;
                            overflow: hidden;
                            
                            .progress-bar-fill {
                                height: 100%;
                                background-color: #4CAF50;
                                transition: width 0.3s ease;
                                
                                &.revenue {
                                    background-color: #2196F3;
                                }
                            }
                        }
                        
                        .stat-value {
                            min-width: 80px;
                            text-align: right;
                            font-size: 0.9em;
                            color: #333;
                        }
                    }
                }
            }
        }
    }
}

.geographic-insights {
    padding: 20px;

    .map-section {
        margin-bottom: 30px;

        .map-container {
            width: 100%;
            height: 400px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 20px;
            background: #f8f9fa;
            border: 1px solid #e0e0e0;
        }

        .map-legend {
            .legend-item {
                display: flex;
                align-items: center;
                gap: 8px;
                margin: 0 10px;

                .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;

                    &.active {
                        background-color: #4ade80;  // Green for active
                    }

                    &.inactive {
                        background-color: #ff6b6b;  // Red for inactive
                    }
                }

                span {
                    font-size: 14px;
                    color: #333;
                }
            }
        }

        .custom-marker {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            cursor: pointer;
            border: 2px solid white;
            box-shadow: 0 0 4px rgba(0,0,0,0.3);
            transition: transform 0.2s;

            &:hover {
                transform: scale(1.2);
            }
        }

        .custom-popup-container {
            .mapboxgl-popup-content {
                padding: 10px;
                border-radius: 6px;
                border: none;
                box-shadow: 0 2px 8px rgba(0,0,0,0.15);
            }

            .mapboxgl-popup-tip {
                border-top-color: white;
            }
        }

        // Style the navigation controls
        .mapboxgl-ctrl-group {
            border: none;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            
            button {
                width: 30px;
                height: 30px;
                
                &:hover {
                    background-color: #f8f9fa;
                }
            }
        }

        // Style the scale control
        .mapboxgl-ctrl-scale {
            border: 1px solid #ddd;
            border-top: none;
            background-color: rgba(255,255,255,0.8);
            padding: 2px 5px;
            color: #666;
            font-size: 10px;
        }
    }

    .customer-overview-section {
        padding: 15px;
        
        .overview-text {
            color: #666;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 1.5;
        }

        .city-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .city-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 8px;
            background: #f5f5f5;
            border-radius: 6px;

            .city-dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                
                &.active {
                    background-color: #4ade80;
                }
                
                &.inactive {
                    background-color: #ff6b6b;
                }
            }

            .city-name {
                font-weight: 500;
                color: #333;
                flex: 1;
            }

            .customer-count {
                color: #666;
                font-size: 13px;
            }
        }
    }
}

.visit-statistics {
    display: flex;
    justify-content: space-around;
    margin-top: 32px;
    padding: 0 32px;
    gap: 48px;

    .stat-item {
        text-align: center;
        flex: 1;
        max-width: 200px;

        h4 {
            margin: 0;
            color: #111827;
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        p {
            margin: 0;
            color: #6B7280;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
        font-size: 16px;
        font-weight: 600;
        color: #111827;
        margin: 0;
    }
}

.graph-legend {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-bottom: 16px;

    .legend-item {
        display: flex;
        align-items: center;
        gap: 8px;

        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;

            &.primary {
                background-color: #4CAF50;
            }

            &.lead {
                background-color: #FFC107;
            }

            &.duration {
                background-color: #2196F3;
            }
        }

        span {
            font-size: 14px;
            color: #111827;
            font-weight: 500;
        }
    }
}

.custom-popup {
    padding: 10px;

    h3 {
        margin: 0 0 5px;
        font-size: 14px;
        font-weight: bold;
    }

    p {
        margin: 3px 0;
        font-size: 12px;
    }
}

.map-container {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
}

.map-legend {
    margin-top: 10px;
    display: flex;
    gap: 15px;
    justify-content: center;

    .legend-item {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;

        .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.active {
                background-color: #4ade80;
            }

            &.inactive {
                background-color: #ff6b6b;
            }
        }
    }
}

.custom-marker {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.2);
    }
}

.city-activity-summary {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    
    .activity-box {
        flex: 1;
        padding: 15px;
        border-radius: 8px;
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);

        h4 {
            margin: 0 0 10px 0;
            color: #666;
            font-size: 14px;
        }

        .city-stats {
            h3 {
                margin: 0 0 10px 0;
                color: #333;
                font-size: 18px;
            }

            .stats-details {
                p {
                    margin: 5px 0;
                    font-size: 13px;
                    color: #666;
                    display: flex;
                    justify-content: space-between;
                    
                    span {
                        font-weight: 600;
                        color: #333;
                    }
                }
            }
        }

        &.most-active {
            border-left: 4px solid #4ade80;
        }

        &.most-inactive {
            border-left: 4px solid #ff6b6b;
        }
    }
}

// Ensure the map container adjusts its height accordingly
.map-section {
    margin-top: 20px;
}

.save-button,
.btn-primary[type="submit"],
.save-action {
  background-color: #1477e7;
  &:hover {
    background-color: darken(#1477e7, 10%);
  }
}
