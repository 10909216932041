.reports-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .report-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    padding: 80px 0px 80px 0px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .download-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }
  
  .description {
    font-size: 12px;
    color: #555;
    margin-top: 10px;
  }
  
 