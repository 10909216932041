@import '../../../styles/mixins/index.scss';

.productunittable-alignment {




    .product-table-design {
        border: 1px solid #e0e2e7;
        border-radius: 8px;
    
        .pagination-alignment {
            padding: 14px 24px;
        }

        input[type="checkbox"] {
            width: 15px;
            height: 15px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
    
            tbody {
                tr {
                    border-bottom: 1px solid #f0f1f3;
                    .three-icon-alignment {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        img {
                            display: block;
                            cursor: pointer;
                        }
                    }
                    td {
                        // padding: 18px 22px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: #1d1f2c;
                        text-align: center;

                        span {
                            display: block;
                            text-align: left;
                        }

                        span .textaline{
                            text-align: right;
                        }
    
                        button {
                            padding: 4px 10px;
                            border: none;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.005em;
                            &.active-button {
                                background: #fff0ea;
                                color: white;
                                background: #5cb85c;

                            }
                        }
    
                        .gray-text {
                            color: black;
                        }
                        .image-text {
                            // display: flex;
                            // align-items: center;
                            // gap: 8px;
                            img {
                                width: 30px;
                                height: 30px;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
    
            thead {
                tr {
                    background: #f0f1f3;
    
                    th {
                        // padding: 18px 22px;
                        font-size: 14px;
    
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: #4a4c56;
                    }
                }
            }
        }
    }


}

.tableSize{
    width: 100%;
}

.tableWidth{
    width: 15%;
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }

  input {
  text-transform: none !important;
}

  
// .productunittable-alignment .product-table-design table tbody tr .three-icon-alignment img {
//     display: block;
//     cursor: pointer;
//     width: 30px;
// }
.productunittable-alignment .product-table-design table tbody tr .three-icon-alignment {
    display: flex
;
    // align-items: baeline;
    gap: 19px;
    // width: 99px;
}

.image-text{
    font-size: 30px !important;
}


  /* Common styles for the button */
button {
    padding: 4px 10px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    line-height: 20px;
    letter-spacing: 0.005em;
  }
  
  /* Styles for the active button */
  button.active-button {
    background: #e9faf7;
    color: #5cb85c;
  }
  
  /* Styles for the inactive button */
  button.inactive-button {
    background: #fff0ea;
    color: #d9534f ; /* Example: red for inactive status */
  }
  
  .text-align-right {
    text-align: right; /* Aligns text inside the <td> or <span> to the right */
    // display: block; /* Ensures span respects text-align property */
  }



  .loading-text{
    color: #6b6868;
    font-size: 7px !important;
}

.table-v2-design {
  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background: #f0f1f3;

        th {
          padding: 12px;
          font-size: 14px;
          font-weight: 500;
          color: #4a4c56;
          text-align: left;
        }
      }

      .search-filter {
        th {
          padding: 8px;
          background: #ffffff;
          
          .icon-text-alignment {
            position: relative;
            display: flex;
            align-items: center;

            input, select {
              width: 100% !important;
              padding: 6px 30px 6px 8px;
              border: 1px solid #ddd;
              border-radius: 4px;
              font-size: 14px;

              &::placeholder {
                color: #999;
                font-size: 13px;
              }

              &:focus {
                outline: none;
                border-color: #4a90e2;
              }
            }

            .icon-align {
              position: absolute;
              right: 8px;
              top: 50%;
              transform: translateY(-50%);
              color: #666;
              
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #f0f1f3;

        &:hover {
          background-color: #f8f9fa;
        }

        td {
          padding: 12px;
          font-size: 14px;
          color: #1d1f2c;
          text-align: left;

          .image-text {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 4px;
            }
          }

          .active-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            background: transparent;
            border: none;
            padding: 6px 12px;
            border-radius: 4px;
            cursor: pointer;

            .dot {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              
              &.active {
                background-color: #5cb85c;
              }
              
              &.inactive {
                background-color: #d9534f !important;
              }
            }
          }

          .three-icon-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
          }
        }
      }
    }
  }

  .pagination-alignment {
    padding: 14px 24px;
    display: flex;
    justify-content: flex-end;
  }

  .camera-icon {
    width: 35px;
    height: 35px;
  }

  .thumbnail {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }

  .no-data-cell {
    text-align: center;
    font-style: italic;
    color: #666;
  }
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.loading-text {
  color: #6b6868;
  font-size: 12px;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}