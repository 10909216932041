.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px;
    border: 1px solid #ddd;
}

.styled-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.styled-table tr:hover {
    background-color: #f1f1f1;
}

.styled-table td {
    color: #555;
}
.typography-small {
    font-size: 14px !important;
  }
  .typography-small1 {
    font-size: 14px !important;
    font-weight: 600 !important;
  }