@import '../../../styles/mixins/index.scss';

.state-type-table {
    padding: 0px;
    z-index: -1;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

// Search input styling
.my-custom-class input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    color: gray;
}

// Status button styles
.active-btn, .in-active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    min-width: 90px;
    color: #000000;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}

// Active status styling
.active-btn {
    .dot {
        background-color: #008000;  // green dot
    }
}

// Inactive status styling
.in-active-btn {
    .dot {
        background-color: #FF0000;  // red dot
    }
}

// Search filter styling
.search-filter {
    th {
        padding: 8px;
        
        .icon-text-alignment {
            position: relative;
            width: 100%;
            
            input, select {
                width: 100%;
                padding: 8px;
                padding-right: 30px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                
                &::placeholder {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 2px;
                }
            }
            
            .icon-align {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}