@import '../../../styles/mixins/index.scss';

.account-table-design {
  padding: 0px;
  z-index: -1;

  @include breakpoint("sm-max") {
    padding: 16px;
  }

  // Status button styles
  .Active {
    background-color: #008000 !important;
  }

  .Inactive {
    background-color: #FF0000 !important;
  }

  .active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
  }

  // Status dot colors
  .Active .dot {
    background-color: #008000;
  }

  .Inactive .dot {
    background-color: #FF0000;
  }
}

// Search and filter styles
.table-v2-design {
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        width: 100%;
        
        input {
          width: 100%;
          padding: 6px 30px 6px 8px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          
          &::placeholder {
            color: #666;
            font-size: 13px;
          }
        }
        
        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }

  .tableSize{
    width: 100%;
    
  }