.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}

.right-contnet-alignment {
    display: flex;
    align-items: center;
    gap: 16px;
        button {
            padding: 10px 16px;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            background: #0d99ff;
            border-radius: 8px;
            color: #fff;
            border: 1px solid transparent;
            cursor: pointer;
    
            &.light-button {
                background: transparent;
                border: 1px solid #C2C6CE;
                color: #1D1F2C;
    
            }
    }
}


.modal-container {
    background-color: white;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.modal-header h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #555;
    cursor: pointer;
}

.modal-body {
    margin: 20px 0;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}

.modal-cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}
.modal-Save-button {
    background-color: #1790b5;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.modal-cancel-button:hover {
    background-color: #d32f2f;
}
