@import '../../../styles/mixins/index.scss';

.table-v2-design {
  padding: 0px;
  z-index: -1;

  @include breakpoint("sm-max") {
    padding: 16px;
  }

  // Search filter styles
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        
        input {
          width: 100%;
          padding: 8px;
          padding-right: 30px;
          border: none;
          background: transparent;
          font-size: 0.75rem;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 2px;
          
          &::placeholder {
            color: gray;
          }
        }

        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

// Status button styles
.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.Active {
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #008000;
    display: inline-block;
  }
}

.Inactive {
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF0000;
    display: inline-block;
  }
}

.background {
  background-color: #fffafa;
}

// .highlight {
//   font-weight: bold;
//   color: #df8393;
// }

.font-color {
  color: #000000;
}