@import "../../styles/mixins/index.scss";

.my-activity-page-alignment {
    .grid {
        display: grid;
        grid-template-columns: 1fr 310px;
        gap: 20px;

        .grid-items:last-child {
            background-color: $theme-white;
            height: calc(100vh - 126px);
            overflow: auto;
            padding: 20px;
            @include breakpoint("sm-max") {
                padding: 16px;
            }
        }
    }
}
