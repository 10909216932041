.pending-order-button {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: black !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.cancel-order-button {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: black !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px !important;
    font-weight: bold;
}

.close-order-button {
    background-color: #0D99FF !important;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.add-order-button {
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: black !important;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

#pdfContent {
    width: 600px;
    /* A4 width - margins (595.28px - 20px * 2) */
    padding: 10px;
    /* Optional inner padding */
    margin: auto;
    font-size: 12px;
    /* Adjust font size for better readability */
    box-sizing: border-box;
    line-height: 1.5;
    /* Improve text spacing */
}

// Table styles
.order-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
        border-bottom: 1px solid #dee2e6;
        padding: 12px;
        text-align: left;
    }

    th {
        background-color: #f8f9fa;
        font-weight: 500;
        font-size: 14px;
        color: #495057;
    }

    td {
        font-size: 14px;
        color: #212529;
    }

    tbody tr:nth-child(even) {
        background-color: #f8f9fa;
    }

    tbody tr:hover {
        background-color: #f2f2f2;
    }
}