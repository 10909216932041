.open-order-button {
    background-color: #27AE60;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.cancel-order-button {
    background-color: #D44545;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px !important;
    font-weight: bold;
}

.back-order-button {
    background-color: #8CA7C6;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.dispatch-order-button {
    background-color: #2D5B91;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 30px;
    font-size: 12px;
    font-weight: bold;
}

.timeline-container {
    height: 600px;
    overflow-y: auto;
    border: none !important;
    background-color: white !important;
    padding: 0px !important;
    padding-right: 10px;
    margin: 0px auto !important;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

// ... rest of the styles from orderview.scss ... 