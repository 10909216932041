.dashboard-card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    .items {
        box-shadow: 0px 2px 4px 0px #0000001f;
        border-radius: 8px;
        background: #f8f9fb;
        border: 1px solid #e0e2e7;

        .items-header {
            padding: 14px 24px;

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 16.45px;
                letter-spacing: 0.005em;
                color: #1d1f2c;
            }
        }

        .items-box {
            background-color: #fff;
            border: 1px solid #e0e2e7;
            padding: 24px;
            border-radius: 8px;

            .text-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                h3 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #000;
                }
                p {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 12px;
                    letter-spacing: 0.005em;
                    color: #525252;
                }
                .icon-text {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin: 8px 0;
                    
                    .growth-icon {
                        width: 20px;
                        height: 20px;
                        transition: all 0.3s ease;
                        
                        &.negative {
                            transform: rotate(180deg);
                            filter: invert(42%) sepia(94%) saturate(1352%) hue-rotate(338deg) brightness(119%) contrast(119%);
                        }
                        
                        &.positive {
                            transform: none;
                            filter: invert(56%) sepia(83%) saturate(409%) hue-rotate(93deg) brightness(92%) contrast(89%);
                        }
                    }
                    
                    span {
                        font-weight: 600;
                        transition: color 0.3s ease;
                    }
                }
            }
        }
    }
}
