@import '../../../styles/mixins/index.scss';

.lead-table-alignment {
    padding: 0px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.page-px-24 {
  .new-white-box-design {
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: #111827;
      margin-bottom: 20px;
    }
  }
}

.profile-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  
    &__section {
      width: 30%;
      display: flex;
      flex-direction: column;
  
      &:first-child {
        width: 20%;
      }
    }
  
    &__avatar {
      width: 150px;
      height: 150px;
      border-radius: 8px;
      overflow: hidden;
      background: #f5f5f5;

      .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .camera-icon {
        font-size: 24px;
        color: #6B7280;
      }
    }
  
    p {
      margin: 5px 0;
      font-size: 14px;
      line-height: 20px;
      
      &:not(:has(strong)) {
        color: #6B7280;
      }
    }
  
    strong {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #111827;
      line-height: 20px;
    }
  }
  
.new-white-box-design {
  div[style*="display: flex"] {
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 5px 0;
      
      &:not(:has(strong)) {
        color: #6B7280;
      }
    }

    strong {
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #111827;
      line-height: 20px;
    }
  }
}
  