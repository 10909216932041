.router-plander-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;

    .router-plander-modal {
        width: 520px;
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;

        .repeted-data{
            .router-box-timeline-all-alignment:first-child {
                .line-center {
                    display: none;
                }
            } 
        }

        .router-box-timeline-all-alignment {
            .line-center {
                width: 1px;
                height: 20px;
                background-color: #e0e0e0;
                margin: 0 auto;
            }
            .router-box-timeline {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 16px;
                background-color: #f5f5f5;
                border-radius: 6px;
        
                .right-content-alignment {
                    display: flex;
                    align-items: center;
                    gap: 20px;
        
                    svg {
                        cursor: pointer;
                    }
                }
        
                span {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: #222;
                }
            }
        }

        .text-style {
            p {
                font-size: 16px;
                color: #222;
                padding: 0 0 16px 0;
                font-weight: 400;

                strong {
                    font-weight: 600;
                }
            }
        }

        h2 {
            color: #1d1f2c;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            margin: 0 0 20px 0;
        }
    }
}
