@import '../../../styles/mixins/index.scss';

.priority-details-all-contnet-alignment {
    padding: 10px 0;

    .grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: -25px;


        @include breakpoint("sm-max") {
            grid-template-columns: 1fr;
            gap: 20px;
            display: block;
        }

        .grid-items {
            h5 {
                @include font14;
                color: $theme-black-100;
                font-weight: 600;
                margin: 0 0 20px 0;
            }

            .radion-button {
                display: grid;
                grid-template-columns: 16px 1fr;
                gap: 10px;
                align-items: center;    

                div {
                    label {
                        @include font14;
                        font-weight: 500;
                        color: $theme-black-100;
                    }
                }
            }
        }
    }
}