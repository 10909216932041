@import '../../../styles/mixins/index.scss';

.mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-white;
    z-index: 9999;

    &.hide {
        transform: translateX(100%);
        transition: .3s ease-in-out;
    }

    &.show {
        transform: translateX(0%);
        transition: .3s ease-in-out;
    }

    .mobile-header {
        padding: 20px;
        border-bottom: 1px solid $theme-border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            @include font14;
            color: $theme-primary-color;
            font-weight: 600;
            font-size: 18px;
        }

        i {
            color: $theme-primary-color;
            cursor: pointer;
        }
    }
}