@import "../../../styles/mixins/index.scss";

.dashboard-header-design {
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e0e2e7;

    .text-style {
        h3 {
            font-size: 20px;
            margin: 0 0 8px 0;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #000;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #525252;
        }
    }

    .dashboard-right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

      
        
    }

    .btn-grp {
        border: 1px solid #e0e2e7;
        padding: 4px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;

        button {
            border: none;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            padding: 12px 20px;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #667085;
            border-radius: 8px;

            &.active-button {
                background: #f8f9fb;
                color: #1d1f2c;
            }
            &:hover {
                background: #f8f9fb;
                color: #1d1f2c;
            }
        }
    }
}

.coming-soon-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  z-index: 1000;
}
