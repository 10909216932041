@import '../../styles/mixins/index.scss';

.price-group-table-alignment {
    padding: 20px;
    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.table-v2-design {
  background: #FFFFFF;
  border: 1px solid #E0E2E7;
  border-radius: 8px;
  
  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 12px 16px;
      text-align: left;
      border-bottom: 1px solid #E0E2E7;
    }

    th {
      background: #F9FAFB;
      color: #4B5563;
      font-weight: 500;
    }

    td {
      color: #111827;
    }

    .status-badge {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      padding: 4px 8px;
      border-radius: 16px;
      font-size: 14px;
      
      &.active {
        background: #ECFDF3;
        color: #027A48;
        
        .status-dot {
          background: #027A48;
        }
      }
      
      &.inactive {
        background: #FEF3F2;
        color: #B42318;
        
        .status-dot {
          background: #B42318;
        }
      }

      .status-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
  }

  .no-data {
    text-align: center;
    font-style: italic;
    color: #666;
    padding: 24px;
  }
}

.design-v2-header {
  margin-bottom: 24px;
}

.design-v2-left-right-alignment {
  padding: 0 24px;
}