.assigned-section-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 32px 0;

    .assigned-right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .btn-grp {
        border: 1px solid #e0e2e7;
        padding: 4px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background-color: #fff;

        button {
            border: none;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            padding: 12px 20px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #667085;
            border-radius: 8px;

            &.active-button {
                background: #f8f9fb;
                color: #1d1f2c;
            }
            &:hover {
                background: #f8f9fb;
                color: #1d1f2c;
            }
        }
    }
}