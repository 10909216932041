@import "../../../styles/mixins/index.scss";

.page-sub-header-design-v2 {
    padding: 24px 32px 16px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search-width {
        width: 320px;
    }

    .page-sub-header-right-alignment {
        display: flex;
        align-items: center;
        gap: 18px;
    }
}

.searchbar-design {
    width: 300px;
    position: relative;

    .icon-alignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
    }
    input {
        width: 100%;
        height: 48px;
        padding-left: 36px;
        font-size: 14px;
        border: 1px solid #e0e2e7;
        box-shadow: 0px 1px 4px 0px #00000014;
        font-weight: 600;
        line-height: 19.6px;
        background: #ffffff;
        letter-spacing: 0.005em;
        color: #4a4c56;
        border-radius: 8px;
    }

    ::placeholder {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        letter-spacing: 0.005em;
        color: #858d9d;
    }
}

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;

    &.Active {
      background-color: #008000;  // green for active
      padding: 4px 4px!important;
    }

    &.Inactive {
      background-color: #FF0000 !important;  // red for inactive
      padding: 4px 4px!important;
    }
  }
}

.table-v2-design {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    
    table {
        width: 100%;
        border-collapse: collapse;
        position: relative;
        
        th, td {
            padding: 12px;
            text-align: left;
        }
        
        thead {
            position: relative;
            z-index: 1;
            tr {
                &:first-child {
                    border-bottom: none;
                }
                
                &.search-filter {
                    th {
                        padding-top: 0;
                        padding-bottom: 16px;
                        border-bottom: 1px solid #E0E2E7;
                    }
                }
            }
        }
        
        tbody {
            position: relative;
            z-index: 0;
        }
    }
    
    .icon-text-alignment {
        position: relative;
        
        input {
            width: 100%;
            padding: 8px 32px 8px 12px;
            border: 1px solid #E0E2E7;
            border-radius: 4px;
            font-size: 14px;
            position: relative;
            z-index: 2;
            background: white;
            cursor: text;
            
            &::placeholder {
                color: #858D9D;
            }

            &:focus {
                outline: none;
                border-color: #1890ff;
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
            }
        }
        
        .icon-align {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
            pointer-events: none;
        }
    }
    
    .profile-text-design {
        display: flex;
        align-items: center;
        gap: 12px;
        
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
        
        div {
            p {
                margin: 0;
                font-weight: 600;
                color: #4A4C56;
            }
            
            span {
                font-size: 12px;
                color: #858D9D;
            }
        }
    }

    .search-filter {
        th {
            padding: 12px 16px;
            position: relative;

            .icon-text-alignment {
                position: relative;
                z-index: 2;
                
                input {
                    width: 100%;
                    padding: 8px 32px 8px 12px;
                    border: 1px solid #E0E2E7;
                    border-radius: 4px;
                    font-size: 14px;
                    position: relative;
                    z-index: 2;
                    background: white;
                    cursor: text;
                    
                    &::placeholder {
                        color: #858D9D;
                    }

                    &:focus {
                        outline: none;
                        border-color: #1890ff;
                        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
                    }
                }
                
                .icon-align {
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 3;
                    pointer-events: none;
                }
            }

            .status-dropdown {
                position: relative;
                z-index: 2;
                
                .status-select {
                    width: 106%;
                    padding: 7px 14px;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    font-size: 13px;
                    background-color: white;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;

                    &:focus {
                        outline: none;
                        border-color: #1890ff;
                        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
                    }

                    option {
                        padding: 8px;
                    }
                }
            }
        }
    }
}

// .dot {
//   width: 8px;
//   height: 8px;
//   border-radius: 50%;
//   display: inline-block;
// }

// // Status dot colors
// .Active .dot {
//   background-color: #008000;  // green for active
// }

// .Inactive .dot {
//   background-color: #FF0000 !important;  // red for inactive
// }