@import "../../../styles/mixins/index.scss";


.account-table-header-alignment {
    padding: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-width {
        max-width: 320px;
    }
    .right-contnet-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
        input {
            width: 136px;
            border: 1px solid #e0e2e7;
            background-color: #fff;
            height: 40px;
            font-size: 14px;
            border-radius: 8px;
            padding: 0 12px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #858d9d;
        }
        button {
            padding: 9px 14px;
            border: 1px solid #e0e2e7;
            background-color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            border-radius: 8px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #858d9d;
            &:focus {
                outline: none;
            }
        }
    }
}