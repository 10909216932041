@import '../../../styles/mixins/index.scss';

.visit-table-design {
    padding: 20px 0;

    .white-box {
        padding: 20px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 6px;

        .table-design-change {
            table {
                width: 100%;
                border-collapse: collapse;

                tbody {
                    tr {
                        border-bottom: 1px solid #e0e0e0;
                        td {
                            padding: 10px;
                            .profile {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                img {
                                    display: block;
                                    width: 32px;
                                    object-fit: cover;
                                    height: 32px;
                                    border-radius: 6px;
                                }

                                small {
                                    display: block;
                                    @include font12;
                                    font-size: 10px;
                                    font-weight: 500;
                                    color: #777;
                                    padding: 4px 0 0 0;
                                }
                            }

                            button {
                                padding: 6px 12px;
                                border: none;
                                color: green;
                                cursor: pointer;
                                @include font12;
                                background-color: #00800026;
                                border-radius: 4px;

                                &:focus {
                                    outline: none;
                                }
                            }
                            span {
                                @include font13-medium;
                                color: #222;
                                display: block;
                            }
                        }
                    }
                }

                thead {
                    tr {
                        background-color: #f5f5f5;
                        th {
                            padding: 10px;
                            @include font13-medium;
                            font-weight: 600;
                            text-align: left;
                            color: #222;
                        }
                    }
                }
            }
        }

        .visit-header {
            display: flex;
            padding: 0 0 20px 0;
            align-items: center;
            justify-content: space-between;
            .right-content {
                display: flex;
                align-items: center;
                gap: 16px;

                span {
                    @include font14;
                    color: #1477e7;
                    font-weight: 500;
                    cursor: pointer;
                }
                .button-group-alignment {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    button {
                      padding: 9px 20px;
                      @include font14;
                      font-weight: 500;
                      border-radius: 6px;
                      border: none;
                      background-color: #f5f5f5;
                      cursor: pointer;
                      color: #222;
                      transition: .3s ease-in-out;
                      &.active-button {
                        background-color: #1477e7;
                        color: #fff;
                      }
                      &:hover {
                        background-color: #1477e7;
                        color: #fff;
                      }
                     
      
                      &:focus {
                        outline: none;
                      }
                    }
                  }
            }
            h2 {
                @include font18;
                color: #222;
            }
        }
    }
}