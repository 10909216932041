@import "../../../styles/mixins/index.scss";

.new-header {
    position: sticky;
    top: 0;
    z-index: 98;
    border-bottom: 1px solid #e0e2e7;
    padding: 20px 32px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
        h2 {
            font-size: 20px;
            margin: 0;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.005em;
            color: #000;
        }
    }

    .header-search {
        width: 320px;
        position: relative;

        input {
            border: 1px solid #e0e2e7;
            width: 100%;
            height: 45px;
            border-radius: 8px;
            padding-left: 45px;
            padding-right: 16px;
            @include font16-medium;
            font-weight: 600;
            color: #505050;
        }

        .search-icon-alignment {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 16px;
            display: flex;
            align-items: center;
        }

        ::placeholder {
            @include font16-medium;
            font-weight: 600;
            color: #bdbdbd;
        }
    }

    .all-icons-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .icons {
            width: 45px;
            height: 45px;
            cursor: pointer;
            border: 1px solid #e0e2e7;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.notification-popup {
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
  }
  
  .notification-popup.open {
    z-index: 1001;
  }
  
  .profile-all-contnet-alignment {
    display: flex;
    align-items: center;
    gap: 20px;
    @include breakpoint("sm-max") {
        gap: 16px;
    }
    i {
        color: $theme-primary-color;
        cursor: pointer;
        display: none;

        @include breakpoint("sm-max") {
            display: block;
        }
    }
}

.profile {
    position: relative;

    .img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    }

    .profile-design {
        position: absolute;
        width: 180px;
        background-color: #fff;
        border: 1px solid #e3e6ed;
        right: -12px;
        top: 50px;
        border-radius: 4px;
        z-index: 1001;

        &.hide {
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            visibility: hidden;
        }

        &.show {
            max-height: 100vh;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            visibility: visible;
        }

        span {
            display: block;
            @include font14;
            color: $theme-black-100;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            padding: 10px 20px;
            &:hover {
                color: $theme-primary-color;
            }
        }
    }
}

.profile-contnet-alignment {
    display: flex;
    align-items: center;
    gap: 12px;
    .right-content {
        display: flex;
        align-items: center;
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #07080b;
        }

        img {
            cursor: pointer;
        }

        span {
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.005em;
            color: #4a4c56;
        }
    }
}

.right-content-alignment {
    display: flex;
    align-items: center;
    gap: 24px;
    .right-content {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
            cursor: pointer;
        }
    }
}

.icons {
    position: relative;
    
    img {
      cursor: pointer;
      width: 24px; 
      height: 24px; 
    }
  
    .notification-count {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: #1477e7;  
      color: white;
      font-size: 12px;  
      font-weight: bold;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid white; 
    }
  }
  

.calendar-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  

  .calendar-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 1400px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
    left: 45px;
  }
  

  .close-btn {
    margin-top: 20px;
    background: #0d99ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start; 
  }
  
  
  .close-btn:hover {
    background: #0d99ff;
  }
  