.fill-white-button-design {
    button {
        cursor: pointer;
        border: 1px solid #E0E2E7;
        box-shadow: 0px 1px 4px 0px #00000014;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #667085;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #fff;

    }
}