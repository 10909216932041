.urgent-class {
    color: red;
}

.routine-class {
    color: green;
}

.default-class {
    color: blue;
}

.location-page-header-alignment {
    .location-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 24px 24px;

        .right-all-contnet-alignment {
            display: flex;
            align-items: center;
            gap: 20px;

            .right-contnet-alignment {
                display: flex;
                align-items: center;
                gap: 16px;

                input {
                    width: 136px;
                    border: 1px solid #e0e2e7;
                    background-color: #fff;
                    height: 40px;

                    font-size: 14px;
                    border-radius: 8px;
                    padding: 0 12px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #858d9d;
                }

                button {
                    padding: 9px 14px;
                    border: 1px solid #e0e2e7;
                    background-color: #fff;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 14px;
                    border-radius: 8px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #858d9d;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .checkbox-text {
                display: flex;
                align-items: center;
                gap: 6px;

                .dot {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: red;
                }

                input[type="checkbox"] {
                    width: 16px;
                    height: 16px;
                    margin: 0;
                    padding: 0;
                    accent-color: #dc7633;
                }

                span {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: #667085;
                }
            }
        }
    }

    .location-grid {
        display: grid;
        grid-template-columns: 260px 1fr;
        gap: 0;
        position: relative;

        .menu-open {
            position: absolute;
            right: 0;
            background: #0d99ff;
            transition: .3s ease-in-out;
            width: 50px;
            height: 40px;
            z-index: 999;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: .3s ease-in-out;
            justify-content: center;

            &.menu-open-change {
                right: 320px;
                transition: .3s ease-in-out;

                svg {
                    transition: .3s ease-in-out;
                    transform: rotate(180deg);
                }
            }

            svg {
                width: 16px;
                height: 16px;
                transition: .3s ease-in-out;

                path {
                    fill: #fff;
                }
            }

        }



        .user-list {
            background-color: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 8px;
            width: 250px;
            padding: 0;
        }

        .user-list h3 {
            font-size: 16px;
            font-weight: 600;
            padding: 10px 16px;
            margin: 0;
            color: #1d1f2c;
            border-bottom: 1px solid #e0e0e0;
        }

        /* User Row */
        .user-row {
            display: flex;
            align-items: center;
            padding: 10px 16px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
        }

        .user-row:hover {
            background-color: #f5f5f5;
        }

        .user-row:hover .error-btn {
            display: inline-block;
        }

        .user-row.active {
            background-color: #504d4d;
        }

        /* Profile Picture Placeholder */
        .profile-pic {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #e0e2e7;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
        }

        .profile-pic i {
            font-size: 20px;
            color: #4b73f5;
        }

        /* User Information */
        .user-info {
            flex-grow: 1;
        }

        .user-name {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            color: #1d1f2c;
        }

        .user-details {
            font-size: 12px;
            color: #d9534f;
            margin: 2px 0 0;
        }

        /* Arrow Button */
        .arrow-btn {
            background-color: #e0e2e7;
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #333;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
        }

        .arrow-btn:hover {
            background-color: #ccc;
        }

        /* Error Button */
        .error-btn {
            display: none;
            background-color: #dc3545;
            color: #fff;
            border: none;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            position: absolute;
            right: 60px;
        }

        .error-btn:hover {
            background-color: #c82333;
        }

        /* Dotted Line */
        .dotted-line {
            border-top: 1px dashed #ccc;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .drawer {
            position: fixed;
            top: 0;
            right: -320px;
            width: 300px;
            height: 75%;
            background-color: #fff;
            box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
            transition: right 0.3s ease-in-out;
            z-index: 1000;
        }

        .drawer.open {
            right: 0;
        }

        .drawer-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            background-color: #4b73f5;
            color: #fff;
        }

        .close-btn {
            background: none;
            border: none;
            font-size: 24px;
            color: white;
            cursor: pointer;
        }

        .drawercontent {
            padding: 16px;
            max-height: 600px;
            overflow-y: auto;
        }

        .drawercontent p {
            margin: 8px 0;
            font-size: 14px;
            color: #333;
        }

        /* User Row Styling */
        .user-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 16px;
            position: relative;
            transition: background-color 0.3s ease-in-out;
        }

        .user-row:hover {
            background-color: #f5f5f5;
        }

        .user-row:hover .error-btn {
            display: inline-block;
        }

        /* Dotted Line After Each Row */
        .dotted-line {
            border-top: 1px dashed #ccc;
            margin: 0 16px;
        }

        .profile-pic {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #e0e2e7;
            /* Light gray background */
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
        }

        .profile-pic i {
            font-size: 20px;
            /* Icon size */
            color: #4b73f5;
            /* Blue color for user icon */
        }

        .user-name {
            font-size: 14px;
            font-weight: 600;
            color: #1d1f2c;
            margin: 0;
        }

        .user-details {
            font-size: 12px;
            color: #d9534f;
            /* Red for Visits and Distance */
            margin: 2px 0 0;
        }

        /* Arrow Button */
        .arrow-btn {
            background-color: #e0e2e7;
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #333;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
        }

        .arrow-btn:hover {
            background-color: #ccc;
        }

        /* Error Button (Hidden by Default) */
        .error-btn {
            display: none;
            background-color: #dc3545;
            /* Red */
            color: #fff;
            border: none;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            position: absolute;
            right: 60px;
        }

        .error-btn:hover {
            background-color: #c82333;
        }


        .user-list h3 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
            color: #1d1f2c;
        }

        .name-dot-grid {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 10px;
            cursor: pointer;
            transition: 0.3s ease-in-out;
        }

        .name-dot-grid:hover {
            background-color: #f5f5f5;
            border-radius: 6px;
        }

        .name-dot-grid.active {
            background-color: #f5f5f5;
        }

        .dot-gray,
        .dot-green {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        .dot-gray {
            background-color: #888;
        }

        .dot-green {
            background-color: green;
        }

        .name-dot-grid p {
            font-size: 14px;
            font-weight: 600;
            color: #1d1f2c;
            margin: 0;
        }

        .name-dot-grid .sub-text {
            display: block;
            font-size: 12px;
            color: #858d9d;
            margin-top: 2px;
        }

        .arrow-btn {
            margin-left: auto;
            width: 30px;
            height: 30px;
            background-color: #e0e2e7;
            border-radius: 50%;
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
        }

        .arrow-btn:hover {
            background-color: #0d99ff;
        }
    }
}

.location-modal {
    position: fixed;
    right: 0;
    width: 320px;
    height: calc(100vh - 161px);
    z-index: 99;
    background-color: #fff;
    top: 146px;
    overflow: auto;

    &.hide {
        transform: translateX(100%);
        transition: .3s ease-in-out;
    }

    &.show {
        transform: translateX(0%);
        transition: .3s ease-in-out;
    }

    .sticky-card {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
    }

    .all-card-box {
        background-color: #f5f5f5;
        padding: 16px;

        .new-grid {
            display: grid;
            grid-template-columns: 8px 1fr;
            gap: 16px;
            position: relative;

            .bottom-sapcing {
                padding: 0 0 12px 0;
            }

            .line {
                position: relative;

                &::after {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    top: 0;
                    left: -4px;
                    background: #0D99FF;
                    content: '';
                    border-radius: 50%;
                }

                &::before {
                    position: absolute;
                    left: 1px;
                    background-color: #c2bfbf;
                    height: calc(100% - 20px);
                    top: 12px;
                    width: 2px;
                    content: '';
                }
            }
        }

        h3 {
            font-size: 16px;
            color: #1d1f2c;
            font-weight: 600;
            letter-spacing: 0.005em;
            margin: 0 0 16px 0;
        }

        .white-box {
            background-color: #fff;
            border-radius: 8px;
            padding: 16px;
            border: 1px solid #e0e0e0;

            strong {
                font-size: 16px;
                font-weight: 600;
                display: block;
                padding: 0 0 4px 0;
                letter-spacing: 0.005em;
                color: #0d99ff;
            }

            p {
                margin: 0;

                font-size: 14px;
                color: #4A4C56;
                font-weight: 500;
            }
        }

        h4 {
            display: block;
            font-size: 12px;
            color: #4A4C56;
            font-weight: 500;
            margin: 0 0 5px 0;
        }
    }

    .sub-header-alignment {
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;

        .all-text-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                display: block;
                font-size: 12px;
                color: #1D1F2C;
                margin: 0;
                text-align: center;
                font-weight: 600;
            }

            span {
                display: block;
                font-size: 12px;
                color: #4A4C56;
                text-align: center;
                font-weight: 500;
            }
        }

        .title-sub-text-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 12px 0;

            p {
                font-size: 16px;
                font-weight: 500;
                color: #0D99FF;
                margin: 0;
            }

            span {
                display: block;
                font-size: 13px;
                color: #4A4C56;

            }

        }
    }

    .location-modal-header {
        background: #45b351;

        .header-four-col {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 0;
            padding: 12px 0 16px 0;


            .items {
                padding: 0 12px;
                border-right: 1px solid #fffefe5c;

                span {
                    display: block;
                    font-size: 14px;

                }

                p,
                span {
                    font-size: 12px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                }

            }
        }

        .header-grid {
            display: grid;
            grid-template-columns: 40px 1fr;
            gap: 12px;
            padding: 16px 16px 10px 16px;

            img {
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }

            p {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.005em;
                color: #fff;
            }

            span {
                display: block;
                font-size: 12px;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}

.map-locator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgb(0, 0, 0, 0.5);
}

.map-locator-modal-md {
    position: fixed;
    top: 148px;
    right: 0;
    width: 360px;
    height: 83vh;
    padding: 0;
    background-color: #fff;
    z-index: 99999;

    &.hide {
        transform: translateX(100%);
        transition: .3s ease-in-out;
    }

    &.show {
        transform: translateX(0%);
        transition: .3s ease-in-out;
    }

    .all-card-box {
        background-color: #f5f5f5;
        padding: 16px;
        height: calc(100vh - 245px);
        overflow: auto;

        .bottom-spacing {
            padding: 0 0 10px 0;
        }

        .new-grid {
            display: grid;
            grid-template-columns: 8px 1fr;
            gap: 16px;
            position: relative;

            .bottom-sapcing {
                padding: 0 0 12px 0;
            }

            .line {
                position: relative;

                &::after {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    top: 0;
                    left: -4px;
                    background: #0D99FF;
                    content: '';
                    border-radius: 50%;
                }

                &::before {
                    position: absolute;
                    left: 1px;
                    background-color: #c2bfbf;
                    height: calc(100% - 20px);
                    top: 12px;
                    width: 2px;
                    content: '';
                }
            }
        }

        h3 {
            font-size: 16px;
            color: #1d1f2c;
            font-weight: 600;
            letter-spacing: 0.005em;
            margin: 0 0 16px 0;
        }

        .white-box {
            background-color: #fff;
            border-radius: 8px;
            padding: 16px;
            border: 1px solid #e0e0e0;

            strong {
                font-size: 16px;
                font-weight: 600;
                display: block;
                padding: 0 0 4px 0;
                letter-spacing: 0.005em;
                color: #0d99ff;
            }

            p {
                margin: 0;

                font-size: 14px;
                color: #4A4C56;
                font-weight: 500;
            }
        }

        h4 {
            display: block;
            font-size: 12px;
            color: #4A4C56;
            font-weight: 500;
            margin: 0 0 5px 0;
        }
    }

    .header-modal {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #45b351;

        h3 {
            font-size: 18px;
            color: #fff;
            font-weight: 500;
            margin: 0;
        }

        .close {
            font-size: 20px;
            color: #fff;
            margin: 0;
        }

    }
}

.location-grid {
    display: grid;
    grid-template-columns: 240px 1fr;
    gap: 0;
    position: fixed;

    .location-grid-items {
        position: fixed;

        //   .white-box-map {
        //     position: absolute;
        //     left: 30px; /* Change 'right' to 'left' */
        //     top: 30px;
        //     width: 380px;
        //     height: 120px;
        //     background-color: #f5f5f5;
        //     z-index: 99999;
        //     float: left;
        //   }
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background-color: #f8f9fa;
        }

        .white-box-map {
            padding: 10px;
            background-color: #ffffff;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            position: absolute;
            left: 30px;
            top: 30px;
            float: left;
        }

        .date-picker-container {
            display: flex;
            height: 100%;
            align-items: center;
            gap: 20px;
            z-index: 111 !important;
        }

        .date-picker-field {
            display: flex;
            flex-direction: column;
        }

        .date-picker-input {
            padding: 10px;
            font-size: 14px;
            border: 1px solid #ced4da;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
        }

        .date-picker-input:focus {
            border-color: #007bff;
            outline: none;
            box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
        }

        .search-button {
            padding: 10px 20px;
            font-size: 14px;
            background-color: #007bff;
            color: #ffffff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }

        .search-button:hover {
            background-color: #0056b3;
        }

    }
}