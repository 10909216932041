@import '../../../styles/mixins/index.scss';

.productcategorytable-alignment {
    padding: 20px;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.table{
    width: 4%;
}

.tablewidth{
    width: 4%;
}

.tablesatus{
    width: 8%;
}

.tablepoint{
    width: 10%;
}

// Search filter styles
.search-filter {
    th {
        padding: 8px;
        
        .icon-text-alignment {
            position: relative;
            display: flex;
            align-items: center;

            input {
                width: 100% !important;
                padding: 6px 30px 6px 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 14px;

                &::placeholder {
                    color: #999;
                    font-size: 13px;
                }

                &:focus {
                    outline: none;
                    border-color: #4a90e2;
                }
            }

            .icon-align {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                color: #666;
                
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

// Status button styles
.active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;

    &.active .dot {
        background-color: #28a745;
    }

    &.inactive .dot {
        background-color: #dc3545 !important;
    }

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
    }
}

// Add a more specific selector for inactive state
// .table-v2-design table tbody tr td .active-btn.inactive .dot {
//     background-color: #dc3545 !important;
// }

.table-v2-design {
    .search-filter {
        th {
            padding: 8px;
            
            .icon-text-alignment {
                position: relative;
                display: flex;
                align-items: center;

                input {
                    width: 100%;
                    padding: 6px 30px 6px 8px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;

                    &::placeholder {
                        color: #999;
                        font-size: 13px;
                    }

                    &:focus {
                        outline: none;
                        border-color: #4a90e2;
                    }
                }

                .icon-align {
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #666;
                    
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .status-select {
        width: 100%;
        padding: 6px 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        
        &:focus {
            outline: none;
            border-color: #4a90e2;
        }
    }
}

.table-v2-design table thead tr.search-filter th .icon-text-alignment input {
    width: 100% !important;
}