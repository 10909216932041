@import '../../../styles/mixins/index.scss';

.payment-type-table {
    padding: 20px;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

    tbody {
      td {
        button.active-btn,
        button.in-active-btn {
          color: #000000 !important;
          .dot {
            width: 8px !important;
            height: 8px !important;
            border-radius: 50% !important;
            display: inline-block !important;
            margin: 0 !important;
          }
        }

        // button.active-btn .dot {
        //   background-color: #28a745 !important;
        // }

        button.in-active-btn .dot {
          background-color: #dc3545 !important;
        }
      }
    }
  
