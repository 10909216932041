.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #000000;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;

    &.Rejected {
      background-color: #ff4d4f !important;  // red for rejected
      width: 8px !important;
      height: 8px !important;
    }

    
    &.Approved {
        background-color: #3ac977 !important;  // green for rejected
        width: 8px !important;
        height: 8px !important;
      }

    &.Pending {
      background-color: #FFA500 !important;  // orange for pending
      width: 8px !important;
      height: 8px !important;
    }
    &.pending {
        background-color: #FFA500 !important;  // orange for pending
        width: 8px !important;
        height: 8px !important;
      }

    &.Cancelled {
      background-color: #FF0000 !important;  // red for cancelled
      width: 8px !important;
      height: 8px !important;
    }

    &.Close {
      background-color: #000000 !important;  // black for closed
      width: 8px !important;
      height: 8px !important;
    }
  }
}

.table-v2-design {
  // ... existing styles ...

  .table-search-input {
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    margin-bottom: 8px;

    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      outline: none;
    }
  }

  .table-date-picker {
    width: 100%;
    
    .ant-picker {
      width: 100%;
    }
  }

  thead {
    tr:first-child {
      th {
        padding: 8px;
        background-color: #fafafa;
      }
    }
  }
}
