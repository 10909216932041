@import '../../../styles/mixins/index.scss';

.paymentcollection-table-page-alignment {
    padding: 0px;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

// .highlight {
//     font-weight: bold;
//     color: #df8393;
// }

.background {
    background-color: #fffafa;
}

.active-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
}

// Status dot colors
.Pending .dot {
    background-color: #FFA500;  // orange for pending
}

.cancelled .dot {
    background-color: #FF0000;  // red for cancelled
}

.open .dot {
    background-color: #008000;  // green for open
}

.dot.red {
    background-color: red !important;
}

.my-custom-class input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    color: gray;
}

.ant-picker-outlined {
    border-style: none;   
}

.table-v2-design {
  .search-filter {
    th {
      .icon-text-alignment {
        position: relative;
        width: 100%;

        .select-box-container {
          width: 100%;

          .css-13cymwt-control {
            min-height: 34px;
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            background: #fff;
            
            &:hover {
              border-color: #80bdff;
            }
          }

          .css-t3ipsp-control {
            min-height: 34px;
            border-radius: 4px;
            border: 1px solid #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            background: #fff;
          }

          .css-1nmdiq5-menu {
            background: #fff;
            border: 1px solid #e6e6e6;
            border-radius: 4px;
            margin-top: 4px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            .css-1n6sfyn-MenuList {
              padding: 0;

              .css-10wo9uf-option {
                padding: 8px 12px;
                cursor: pointer;
                
                &:hover {
                  background-color: #f8f9fa;
                }
              }

              .css-tr4s17-option {
                background-color: #e9ecef;
                color: #212529;
              }
            }
          }

          .css-1dimb5e-singleValue {
            color: #495057;
          }

          .css-1xc3v61-indicatorContainer {
            color: #6c757d;
            
            &:hover {
              color: #495057;
            }
          }

          .css-1u9des2-indicatorSeparator {
            display: none;
          }

          .css-qbdosj-Input {
            color: #495057;
            margin: 0;
            padding: 0;
          }

          .css-1wa3eu0-placeholder {
            color: #6c757d;
          }
        }

        .date-picker-filter {
          width: 100%;
          height: 34px;
          border-radius: 4px;
          border: 1px solid #e6e6e6;

          &:hover {
            border-color: #80bdff;
          }

          &.ant-picker-focused {
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }

          .ant-picker-input {
            input {
              font-size: 14px;
              color: #495057;

              &::placeholder {
                color: #6c757d;
              }
            }
          }

          .ant-picker-clear {
            background: #fff;
            color: #6c757d;

            &:hover {
              color: #495057;
            }
          }

          .ant-picker-suffix {
            color: #6c757d;
          }
        }
      }
    }
  }
}

.select-box-container {
  .css-319lph-ValueContainer {
    padding: 2px 8px;
  }

  .css-6j8wv5-Input {
    margin: 0;
    padding: 0;
  }

  .css-1xc3v61-indicatorContainer {
    padding: 6px;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }
}

@media (max-width: 768px) {
  .table-v2-design {
    .search-filter {
      th {
        .icon-text-alignment {
          .select-box-container {
            .css-13cymwt-control,
            .css-t3ipsp-control {
              min-height: 30px;
            }
          }
        }
      }
    }
  }
}

// Additional styles for the date picker dropdown
.ant-picker-dropdown {
  .ant-picker-panel-container {
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .ant-picker-panel {
      border: none;
    }

    .ant-picker-date-panel {
      .ant-picker-header {
        border-bottom: 1px solid #e6e6e6;
      }

      .ant-picker-cell {
        &-in-view {
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background: #1890ff;
            }
          }
        }
      }
    }
  }
}
      
    
     
      
.width111{
  width: 168px !important;
}