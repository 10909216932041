@import '../../../styles/mixins/index.scss';

.order-table {

    padding: 0px;
    z-index: -1;

    @include breakpoint("sm-max") {
        padding: 16px;
    }
}

.my-custom-class input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    color: gray;
    
    
}
.ant-picker-outlined{
 border-style: none;   
}

.tableSize{
    width: 100%;
}

.search-input::placeholder {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    padding: 3px;
    border-bottom: 2px solid #edf2f9;
  }
  .ant-picker-outlined {
    border-style: none;
    height: 38px;
}

.w-100 {
    width: 100% !important;
    // height: 38px;
}
.css-15ocp81-control {
    margin-top: -3px !important;
}

.whitesapce-nowrap{
    font-weight: 500 !important;
}

.price-group-text {
    font-weight: 500 !important;
  
}

.active-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  color: #666;

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #52c41a;

    &.inactive {
      background-color: #ff4d4f;
    }
  }
}

.table-v2-design {
  .search-filter {
    th {
      padding: 8px;
      
      .icon-text-alignment {
        position: relative;
        display: flex;
        align-items: center;

        input, select {
          width: 100%;
          padding: 6px 30px 6px 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;

          &::placeholder {
            color: #999;
            font-size: 13px;
          }

          &:focus {
            outline: none;
            border-color: #4a90e2;
          }
        }

        .icon-align {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          color: #666;
          
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }

  .date-range-picker-wrapper {
    .ant-picker {
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      
      &:hover {
        border-color: #40a9ff;
      }
      
      &.ant-picker-focused {
        border-color: #40a9ff;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }
  }
}

.font-color {
  color: #000000;
}

.ant-picker-dropdown {
  z-index: 1050;
}

.select-wrapper {
  width: 100%;
  .select__control {
    border: none;
    background: transparent;
    box-shadow: none;
  }
  .select__value-container {
    padding: 0;
  }
}