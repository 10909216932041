body {
  margin: 0;
  padding: 0;
 font-family: "Source Sans 3", sans-serif !important;
  background: #fcfcfd;
}

button {
 font-family: "Source Sans 3", sans-serif !important;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p:last-child {
  margin: 0;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 999px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(28, 129, 172);
  border-radius: 999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
}
