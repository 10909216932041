.dispatch-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #fff;
    
    &__title {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      color: #111827;
    }
    
    &__actions {
      display: flex;
      gap: 12px;
    }
    
    &__button {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 8px 16px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s, border-color 0.2s;
      
      &--secondary {
        background-color: #fff;
        border: 1px solid #d1d5db;
        color: #4b5563;
        
        &:hover {
          background-color: #f9fafb;
        }
      }
      
      &--primary {
        background-color: #1477e7;
        border: 1px solid #1477e7;
        color: #fff;
        
        &:hover {
          background-color: #1477e7;
          border-color: #1477e7;
        }
      }
    }
    
    &__icon {
      display: inline-block;
    }
  }
  
  .container {
    padding: 20px;
  }
  
  .dispatch-timeline-container {
    display: flex;
    gap: 20px;
    
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  
  // Dispatch Form Styles
  .dispatch-form {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .dispatch-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .dispatch-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .dispatch-number {
    font-size: 18px;
    font-weight: bold;
  }
  
  .dispatch-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .left-details, .right-details {
    flex: 1;
  }
  
  .detail-row {
    margin-bottom: 8px;
    display: flex;
  }
  
  .label {
    font-weight: 500;
    min-width: 120px;
    color: #555;
  }
  
  .value {
    font-weight: normal;
  }
  
  .section-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 15px 0;
  }
  
  .lr-details, .product-details {
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #e0e0e0;
    padding: 8px 12px;
    text-align: left;
  }
  
  .product-table th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  .product-cell {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    
    &.black {
      background-color: #000;
    }
  }
  
  .total-row {
    font-weight: bold;
  }
  
  .terms-conditions {
    margin-top: 20px;
  }
  
  .terms-conditions p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
  }
  
  // Timeline Styles
  .timeline-view {
    width: 350px;
    max-height: 670px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .timeline-header h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  
  .add-button {
    background-color: #9e9e9e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    
    &:hover {
      background-color: #8e8e8e;
    }
  }
  
 
  
  .timeline-entry {
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    
    &:last-child {
      border-bottom: none;
    }
    
    p {
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
    }
    
    .time {
      font-weight: 500;
      margin: 0 4px;
    }
  }
  
    